import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useCurrentRoute } from 'react-navi';
import { ReactComponent as DocumentCopyIcon } from 'imgs/copy-icon.svg';
import { capitalize } from 'helpers/common';
import { copyWithTooltip } from 'helpers/copy';
import { maskEmailAddress } from 'helpers/common';
import { MediaType } from 'helpers/enums';
import { useTheme } from 'hooks/theme';
import { useOldVersionHref } from 'hooks/translation';
import { Button } from 'components/button';
import { NotificationDropdown } from 'components/notificationDropdown';
import { Responsive } from 'components/responsive';
import { AccountContext } from 'modules/accountContext';
import {
  ScAccountInfo,
  ScHeaderBlock,
  ScSwitchAccountTypeBlock,
  ScAuthButton,
} from 'components/header/styled';

import { AccountBlock } from './AccountBlock';
import { ScAccountInfoSection } from './AccountBlock/styled';

export const AnonymousHeader = () => {
  const { t } = useTranslation();
  const { images } = useTheme();
  const {
    url: { pathname },
  } = useCurrentRoute();
  return (
    <ScHeaderBlock anonymous>
      <header className="anonymous-header">
        <Link
          href={process.env.REACT_APP_LANDING_PAGE_URL || '/'}
          className="logo"
        >
          <images.logo.component />
        </Link>
        <ScAuthButton>
          {pathname === '/auth/login' ? (
            <Button primary to="/auth/register">
              {capitalize(t('common:signUp'))}
            </Button>
          ) : (
            <Button primary to="/auth/login">
              {capitalize(t('common:enter'))}
            </Button>
          )}
        </ScAuthButton>
      </header>
    </ScHeaderBlock>
  );
};

export interface AuthenticatedHeaderProps {
  withAccountOptions?: boolean;
}

export const AuthenticatedHeader: FC<AuthenticatedHeaderProps> = ({
  withAccountOptions = true,
}) => {
  const { t } = useTranslation();

  const { images, isPersonalAvailable } = useTheme();

  const account = useContext(AccountContext);

  const oldVersion = useOldVersionHref(process.env.REACT_APP_OLD_VERSION_URL);

  return (
    <ScHeaderBlock>
      <header>
        <Link href="/" className="logo">
          <Responsive
            desktop={<img src={images.logo.path} alt="" />}
            tablet={MediaType.DESKTOP}
            mobile={<img src={images.logoMobile.path} alt="" />}
          />
        </Link>
        {isPersonalAvailable && (
          <ScSwitchAccountTypeBlock>
            <ul>
              {account && account.useApi ? (
                <li>
                  <Link href="/shops" activeClassName="active">
                    {t('common:header.business')}
                  </Link>
                </li>
              ) : null}
              <li>
                <Link href="/personal" activeClassName="active">
                  {t('common:header.personal')}
                </Link>
              </li>
            </ul>
          </ScSwitchAccountTypeBlock>
        )}
        <ScAccountInfo>
          {oldVersion && (
            <a className="link-old-version" href={oldVersion} target="_self">
              {t('common:header.oldVersion')}
            </a>
          )}
          <NotificationDropdown />
          <ScAccountInfoSection>
            <div className="email ph-no-capture">
              {maskEmailAddress(account.email)}
            </div>
            <div
              className="account-number ph-no-capture"
              title={capitalize(t('copy'))}
              onClick={() => copyWithTooltip(account.accountNumber.toString())}
            >
              {account.accountNumber} <DocumentCopyIcon />
            </div>
          </ScAccountInfoSection>
          {withAccountOptions && <AccountBlock />}
        </ScAccountInfo>
      </header>
    </ScHeaderBlock>
  );
};
