import React, { FC, useContext, useMemo, useState, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { AccountContext } from 'modules/accountContext';
import { useComponentVisible } from 'hooks/visibility';
import { AuthServiceContext } from 'modules/authService/context';
import { showTooltip } from 'helpers/tooltip';
import { translationFactory } from 'helpers/trans';
import { AccountStatus } from 'helpers/enums';
import { TooltipTypes } from 'modules/globalTooltip';
import { ScSimpleLink } from 'components/button/styled';
import { Link } from 'react-navi';
import Loader from 'components/loader';
import { capitalize } from 'helpers/common';
import { Avatar } from './Avatar';
import { ScAccountDropDown, ScAccountNav, ScAvatarIcon } from './styled';

import { ReactComponent as OkIcon } from 'imgs/nav/ok-icon.svg';
import { ReactComponent as SecurityIcon } from 'imgs/nav/security-icon.svg';
import { ReactComponent as ProfileIcon } from 'imgs/nav/profile-icon.svg';
import { ReactComponent as TurnOffIcon } from 'imgs/nav/turn-off-icon.svg';
import { ReactComponent as EarthIcon } from 'imgs/nav/earth-icon.svg';
import { ReactComponent as ProtectionIcon } from 'imgs/nav/protection-icon.svg';
import { ReactComponent as ArrowDown } from 'imgs/filled-arrow-down.svg';

export const AccountBlock: FC = () => {
  const { ref, openerRef, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const toggleDropdown = () => {
    setIsComponentVisible((i) => !i);
  };

  const authService = useContext(AuthServiceContext);

  const [logoutPending, setLogoutPending] = useState(false);

  const handleLogout = async () => {
    if (logoutPending) return;

    setLogoutPending(true);

    try {
      await authService.logout();
    } catch (e) {
      setLogoutPending(false);

      showTooltip({
        type: TooltipTypes.ERROR,
        translate: true,
        body: translationFactory('serverError.unknown'),
      });
    }
  };

  return (
    <>
      <Loader on={logoutPending} />

      <ScAvatarIcon
        ref={openerRef}
        isActive={isComponentVisible}
        onClick={toggleDropdown}
      >
        <Avatar />
        <ArrowDown />
      </ScAvatarIcon>

      {isComponentVisible && (
        <AccountDropDown
          ref={ref}
          onClose={toggleDropdown}
          onLogout={handleLogout}
        />
      )}
    </>
  );
};

type LinkType = {
  name: string;
  link: string;
  icon?: JSX.Element;
  target?: string;
  onClick?: () => void;
  extraNode?: ReactNode;
};

const AccountDropDown = React.forwardRef<
  HTMLDivElement,
  {
    onClose: () => void;
    onLogout: () => Promise<void>;
  }
>(({ onClose, onLogout }, ref) => {
  const { t } = useTranslation('common');

  const { status } = useContext(AccountContext);

  const notVerified = useMemo(
    () =>
      status === AccountStatus.EMAIL_VERIFIED ||
      status === AccountStatus.VERIFYING,
    [status]
  );

  const linksGroups = useMemo<LinkType[][]>(() => {
    const links: LinkType[][] = [];

    const StatusVerifiedDropDown = () =>
      notVerified ? (
        <div className="status-verified">
          <span className="status">{t('status.notVerified')}</span>
        </div>
      ) : (
        <div className="status-verified">
          <span className="status verified">{t('status.verified')}</span>
        </div>
      );

    const propfileLink = [
      {
        name: t('header.accountDropdown.profile'),
        link: '/personal/settings/profile',
        icon: <ProfileIcon />,
        extraNode: <StatusVerifiedDropDown />,
      },
    ];

    links.push(propfileLink);

    if (notVerified) {
      links.push([
        {
          name: t('header.verificationCenter'),
          link: '/personal/verification',
          icon: <OkIcon />,
        },
      ]);
    }

    const primaryLinks = [
      // {
      //   name: t('header.accountDropdown.cards'),
      //   link: '/personal/settings/cards',
      //   icon: <CardIcon />,
      // },
      {
        name: t('header.accountDropdown.security'),
        link: '/personal/settings/security',
        icon: <SecurityIcon />,
      },
      {
        name: t('header.accountDropdown.auth'),
        link: '/personal/settings/auth',
        icon: <ProtectionIcon />,
      },
      {
        name: t('header.accountDropdown.time'),
        link: '/personal/settings/time',
        icon: <EarthIcon />,
      },
      // {
      //   name: t('header.accountDropdown.app'),
      //   link: '/personal/settings/app',
      //   icon: <MobileIcon />,
      // }
    ];

    links.push(primaryLinks);

    // if (nameTheme === 'kvitum') {
    //   links.push([
    //     {
    //       name: 'AML',
    //       link: `${process.env.REACT_APP_LANDING_PAGE_URL}/aml.html`,
    //       target: '_blank',
    //     },
    //     {
    //       name: t('header.accountDropdown.privacyPolicy'),
    //       link: `${process.env.REACT_APP_LANDING_PAGE_URL}/privacy.html`,
    //       target: '_blank',
    //     },
    //     {
    //       name: t('header.accountDropdown.userAgreement'),
    //       link: `${process.env.REACT_APP_LANDING_PAGE_URL}/agreement.html`,
    //       target: '_blank',
    //     },
    //   ]);
    // }

    links.push([
      {
        name: t('header.logout'),
        link: '',
        icon: <TurnOffIcon />,
        onClick: onLogout,
      },
    ]);

    return links;
  }, [t, notVerified, onLogout]);

  return (
    <ScAccountDropDown ref={ref}>
      <ScAccountNav>
        {linksGroups.map((group, index) => (
          <ul key={index}>
            {group.map(
              ({ name, link, icon, target, onClick, extraNode }, index) => (
                <li key={index}>
                  {onClick ? (
                    <ScSimpleLink
                      underline
                      className={icon ? 'with-icon' : ''}
                      onClick={onClick}
                    >
                      {icon && icon}
                      {capitalize(name)}
                    </ScSimpleLink>
                  ) : (
                    <Link
                      className={icon ? 'with-icon' : ''}
                      href={link}
                      target={target}
                      onClick={onClose}
                    >
                      {icon && icon}
                      {capitalize(name)}
                      {extraNode ? extraNode : null}
                    </Link>
                  )}
                </li>
              )
            )}
          </ul>
        ))}
      </ScAccountNav>
    </ScAccountDropDown>
  );
});
