import styled from 'styled-components/macro';

import { device } from 'components/global/MediaQuery';

import { ScButton } from 'components/button/styled';

interface ScHeaderBlockPros {
  anonymous?: boolean;
}

export const ScHeaderBlock = styled.div<ScHeaderBlockPros>`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  @media (${device.tablet}) {
    padding-left: 0;
  }
  header {
    margin: 20px auto;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: ${({ anonymous }) => (anonymous ? '1268px' : '928px')};
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.backgroundColorBlock};
    border: 1px solid ${({ theme }) => theme.headerBorderColor};
    border-radius: 12px;
    .logo {
      display: inline-flex;
      align-items: center;
      height: 29px;
      flex-shrink: 0;
      & > img {
        height: 100%;
      }
      @media (${device.tablet}) {
        overflow: hidden;
        height: 45px;
      }
    }
    &.anonymous-header {
      display: flex;
      position: relative;
      z-index: 2;
      background-color: transparent;
      border: none;
      padding-left: 0;
      padding-right: 0;
      .logo {
        height: 50px;
        @media (${device.tablet}) {
          overflow: hidden;
          max-width: 180px;
          height: 35px;
        }
      }
    }
  }
`;

export const ScAccountInfo = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  position: relative;
  padding-top: 2px;
  @media (${device.desktop}) {
    padding-top: 0;
    margin-right: 10px;
  }

  a,
  button {
    color: ${({ theme }) => theme.textColorCaption};
    font-weight: 400;

    &.link-old-version {
      text-transform: uppercase;
      font-size: 12px;
      padding: 0 28px;

      &:hover {
        text-decoration: none;
        color: ${({ theme }) => theme.linkColorHover};
      }

      @media (${device.desktop}) {
        display: none;
      }
    }
  }
`;

export const ScSwitchAccountTypeBlock = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  margin-left: 60px;
  @media (${device.desktop}) {
    display: none;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-self: stretch;
  }
  li {
    display: flex;
    align-items: center;
    margin-right: 25px;
    &:last-child {
      margin: 0;
    }
    a,
    button {
      color: #3b4754;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      transition: color 0.3s ease;
      font-weight: 500;
      text-decoration: none;
      &:hover,
      &.active {
        color: ${({ theme }) => theme.mainColor};
      }
    }
  }
`;

export const ScAuthButton = styled.div`
  ${ScButton} {
    min-width: 188px;
    @media (${device.desktop}) {
      min-width: auto;
    }
  }
`;
