import styled from 'styled-components/macro';
import { device } from 'components/global/MediaQuery';

export const ScLangDropdown = styled.div`
  position: relative;
  .header {
    font: 400 14px/24px ${({ theme }) => theme.mainFontFamily};
    display: flex;
    align-items: center;
    text-align: center;
    color: ${({ theme }) => theme.textColorSecondary};
    cursor: pointer;
    transition: opacity 0.3s;
    & > svg {
      vertical-align: middle;
      margin-right: 5px;
      path {
        fill: ${({ theme }) => theme.textColorSecondary};
      }
    }
    &:hover {
      opacity: 0.6;
    }
  }
  .menu {
    position: absolute;
    bottom: calc(100% + 10px);
    width: 132px;
    padding: 10px 0;
    margin: 0;
    background: ${({ theme }) => theme.dropdownBackground};
    box-shadow: ${({ theme }) => theme.dropdownShadow};
    border-radius: 6px;
    @media (${device.tablet}) {
      left: 50%;
      transform: translateX(-50%);
    }
    & > li {
      padding: 6px 16px;
      list-style: none;
      transition-duration: 0.3s;
      text-align: left;
      cursor: pointer;
      transition: opacity 0.3s;
      @media (${device.tablet}) {
        text-align: center;
      }
      &:hover {
        background-color: ${({ theme }) => theme.dropdownItemHover};
      }
    }
  }
`;
