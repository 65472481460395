import { compose, lazy, map, mount, redirect } from 'navi';
import { Router, View } from 'react-navi';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { Layout } from 'components/Layout';
import {
  loginRedirect,
  withAuthentication,
  withTranslation,
} from 'helpers/routes';
import { AuthServiceContext } from 'modules/authService/context';
import { useAuthStore } from 'modules/authService/store';
import { GlobalRouteContext } from 'types/routes';
import { useTheme } from 'hooks/theme';
import { omniWidget } from '3rdParty/omniWidget';

function redirectFromPathLanguage(lng: string) {
  return redirect(
    (request) => {
      let path = request.path;

      if (request.search) {
        path += request.search + `&lng=${lng}`;
      } else {
        path += `?lng=${lng}`;
      }
      return path + request.hash;
    },
    { exact: false }
  );
}

const routes = compose(
  withTranslation(['common', 'glossary', 'validation']),
  mount({
    '/ru': redirectFromPathLanguage('ru'),
    '/en': redirectFromPathLanguage('en'),
    '/uk': redirectFromPathLanguage('uk'),
    '/pl': redirectFromPathLanguage('pl'),

    '/bill/pay/:billEncodedToken': map<GlobalRouteContext>(
      (request, context) => {
        const { billEncodedToken } = request.params;
        const billPaymentPath = `/personal/bill/${billEncodedToken}`;
        if (context.userAuthenticated) {
          return redirect(billPaymentPath);
        }
        const { setEncodedBillId } = useAuthStore.getState();
        setEncodedBillId(billEncodedToken);
        return loginRedirect(
          billPaymentPath,
          `bill=${encodeURIComponent(billEncodedToken)}`
        );
      }
    ),
    '/registration/confirm/:token': redirect((request) => {
      const confirmToken = request.params.token;
      return `/auth/register?confirm=${confirmToken}`;
    }),
    '/auth': lazy(() => import('modules/auth/route')),
    '*': withAuthentication(lazy(() => import('modules/entrypoint'))),
    '/': map<GlobalRouteContext>((_, context) => {
      if (context.theme.isPersonalAvailable) {
        return redirect('/personal');
      }
      return redirect('/shops');
    }),
  })
);

function setAuthenticationBreadcrumb(isAuthenticated: boolean) {
  if (isAuthenticated) {
    Sentry.addBreadcrumb({
      category: 'auth',
      message: 'User logged in',
      level: 'info',
    });
  } else {
    Sentry.addBreadcrumb({
      category: 'auth',
      message: 'User logged out',
      level: 'info',
    });
  }
}

function setSentryUser(isAuthenticated: boolean) {
  if (!isAuthenticated) {
    Sentry.setUser({ id: 'anonymous' });
  }
}

function setOmniWidget(isAuthenticated: boolean) {
  if (isAuthenticated) {
    omniWidget.add();
  } else {
    omniWidget.remove();
  }
}

export const Routes = () => {
  const authService = useContext(AuthServiceContext);

  const [isUserAuthenticated, setIsUserAuthenticated] = useState(
    authService.isAuthenticated
  );

  useEffect(() => authService.subscribe(setIsUserAuthenticated), [authService]);
  useEffect(
    () => authService.subscribe(setAuthenticationBreadcrumb),
    [authService]
  );

  useEffect(() => authService.subscribe(setOmniWidget, true), [authService]);
  useEffect(() => authService.subscribe(setSentryUser, true), [authService]);

  const theme = useTheme();

  return (
    <Router
      routes={routes}
      context={{ userAuthenticated: isUserAuthenticated, authService, theme }}
    >
      <Layout>
        <Suspense fallback={null}>
          <View />
        </Suspense>
      </Layout>
    </Router>
  );
};
