import React, { useEffect } from 'react';
import { Link, useCurrentRoute } from 'react-navi';
import { useTranslation } from 'react-i18next';

import { useComponentVisible } from 'hooks/visibility';
import { useApi } from 'hooks/api';
import { getNotifications, getUnreadNotifications } from 'api/notifications';
import { useDateFormat } from 'modules/accountContext/utils';

import { ScNotificationDropdown, ScNotificationBadge } from './styled';
import { ReactComponent as NotificationIcon } from 'imgs/notification-icon.svg';
import Loader from 'components/loader';

export const NotificationDropdown = () => {
  const { ref, openerRef, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const { t } = useTranslation('common');

  const {
    url: { pathname },
  } = useCurrentRoute();

  const [unreadNotificationsReq, { data: count }] = useApi(
    getUnreadNotifications
  );

  useEffect(() => {
    unreadNotificationsReq();
  }, [unreadNotificationsReq, pathname]);

  return (
    <ScNotificationDropdown active={!!count}>
      <div
        ref={openerRef}
        className="dropdown__header"
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        <NotificationIcon />
        {count == null || count === 0 ? null : <ScNotificationBadge />}
      </div>
      {isComponentVisible && (
        <div
          className="dropdown__menu"
          ref={ref}
          onClick={() => setIsComponentVisible(false)}
        >
          <Notifications />
          <div className="dropdown__bottom">
            <Link href="/personal/notifications">{t('notifications.all')}</Link>
          </div>
        </div>
      )}
    </ScNotificationDropdown>
  );
};

const NOTIFICATIONS_LIMIT = 3;

const Notifications = () => {
  const { t } = useTranslation('common');

  const format = useDateFormat();

  const [notificationsReq, { data: notifications, pending }] =
    useApi(getNotifications);

  useEffect(() => {
    notificationsReq(NOTIFICATIONS_LIMIT);
  }, [notificationsReq]);

  return (
    <ul className="dropdown__list">
      <Loader inline on={pending} />

      {!pending &&
        (notifications == null || notifications.length === 0 ? (
          <div className="not-found">{t('notifications.notFound')}</div>
        ) : (
          notifications.map(({ id, created, subject, body }) => (
            <li key={id}>
              <Link href="/personal/notifications">
                <div>
                  <p className="title">{subject}</p>
                  <p className="content">{body}</p>
                </div>
                <p className="date">{format(created, 'dd MMM, HH:mm')}</p>
              </Link>
            </li>
          ))
        ))}
    </ul>
  );
};
