import { factory } from 'api/base';
import { snakify } from 'helpers/common';

interface Notification {
  id: number;
  created: string;
  subject: string;
  body: string;
  seen: boolean;
}

export const getNotifications = factory(
  (r) => (limit?: number) =>
    r<Notification[]>('get', '/notifications', snakify({ limit }))
);

export const getUnreadNotifications = factory(
  (r) => () => r('get', '/notifications/count')
);

export const markAsReadNotification = factory(
  (r) => (id: number) => r<null>('put', `/notifications/${id}/mark`, {})
);
