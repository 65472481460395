import React from 'react';
import { NotFoundError } from 'navi';
import { Trans } from 'react-i18next';
import { Link } from 'react-navi';

import { ScNotFoundBlock, ScWrap } from 'components/block/styled';
import { AuthenticatedHeader } from 'components/header';
import { AuthenticatedFooter } from 'components/footer';
import { ScButton } from 'components/button/styled';

import { ReactComponent as NotFoundIcon } from 'imgs/not-found-icon.svg';

export const NotFoundBlock = ({ error }: { error: NotFoundError }) => {
  return (
    <ScNotFoundBlock>
      <NotFoundIcon />
      <Trans i18nKey="common:notFound">
        <h2>
          {/*@ts-ignore*/}
          Page <b>{{ page: error.pathname }}</b> was not found
        </h2>
        <p className="text-grey">
          Maybe you mistyped URL or this page does not longer exist.
          <br />
        </p>
        <p className="text-grey">
          If you believe that this page should exist - contact our support team
          <a href={`mailto:${process.env.REACT_APP_SUPPORT_MAIL}`}>
            {/*@ts-ignore*/}
            {{ support: process.env.REACT_APP_SUPPORT_MAIL }}
          </a>
        </p>
        <ScWrap>
          <Link className="button-link" href="/">
            <ScButton primary>Back to main</ScButton>
          </Link>
        </ScWrap>
      </Trans>
    </ScNotFoundBlock>
  );
};

export const NotFoundLayout = (error: NotFoundError) => (
  <>
    <AuthenticatedHeader withAccountOptions={false} />

    <main>
      <section>
        <NotFoundBlock error={error} />
      </section>
    </main>

    <AuthenticatedFooter />
  </>
);
