import styled from 'styled-components/macro';
import { device } from 'components/global/MediaQuery';

interface ScNotificationDropdownProps {
  active: boolean;
}

export const ScNotificationDropdown = styled.div<ScNotificationDropdownProps>`
  align-self: center;
  z-index: 10;
  .dropdown__header {
    position: relative;
    width: fit-content;
    padding: 6px;
    top: -1px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 14px;
    cursor: pointer;
    transition: 0.3s;
    & > svg {
      width: 24px;
      height: 24px;
      path {
        stroke: ${({ active }) => (active ? '#72ABEB' : '#727B86')} 
      }
    }
  }
  .dropdown__menu {
    position: absolute;
    top: calc(100% + 5px);
    right: 0;
    width: 385px;
    background: #ffffff;
    border: 1px solid ${({ theme }) => theme.dividersColor}
    box-sizing: border-box;
    box-shadow: ${({ theme }) => theme.dropdownShadow};
    border-radius: 12px;
    padding: 8px 20px;
    @media (${device.mobileM}) {
      width: 280px;
    }
    .dropdown__list {
      margin: 0;
      padding: 0;
      list-style: none;
      position: relative;
      min-height: 50px;
      .not-found {
        text-align: center;
        white-space: nowrap;
        border-bottom: 1px solid #f3f3f4;
        padding: 12px 0;
        letter-spacing: 0.012em;
        color: #6c727c;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
      }
      & > li {
        & > a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px 0;
          letter-spacing: 0.012em;
          border-bottom: 1px solid ${({ theme }) => theme.dividersColor};
          text-decoration: none;
          @media (${device.mobileM}) {
            flex-wrap: wrap;
          }
        }
        p {
          cursor: pointer;
        }
        .title {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: ${({ theme }) => theme.textColorPrimary};
          margin: 0 0 4px 0;
        }
        .content {
          font-size: 12px;
          line-height: 14px;
          color: ${({ theme }) => theme.textColorCaption};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 243px;
          margin: 0;
          @media (${device.mobileM}) {
            max-width: 195px;
          }
        }
        .date {
          font-size: 11px;
          line-height: 13px;
          color: ${({ theme }) => theme.textColorCaption};
          margin: 0 0 0 10px;
          white-space: nowrap;
          @media (${device.mobileM}) {
            margin: 10px 0 0 0;
          }
        }
      }
    }
    .dropdown__bottom {
      text-align: center;
      & > a {
        display: inline-block;
        text-decoration: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: ${({ theme }) => theme.linkColor};
        padding: 16px 0;
      }
    }
  }
`;

export const ScNotificationBadge = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  right: 9px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #f78171;
`;
