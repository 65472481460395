import React from 'react';
import { createRoot } from 'react-dom/client';
import App from 'app/app';
import 'libs/posthog';
import 'libs/i18n';

import(`../themes/${process.env.THEME}`)
  .then((module) => {
    const container = document.getElementById('root');
    if (container) {
      const root = createRoot(container);
      root.render(<App theme={module.default} />);
    }
  })
  .catch((e) => {
    throw new Error(e as string);
  });
