import { APIParams, factory, PeekAPIParams, TwoFactor } from 'api/base';
import {
  AccountStatus,
  RecaptchaVersion,
  TokenType,
  TwoFactorAction,
  TwoFactorType,
  VerificationMethod,
} from 'helpers/enums';
import { constructParams } from 'helpers/api';
import { snakify } from 'helpers/common';
import { Balance } from 'api/contracts';

export interface Recaptcha {
  'g-recaptcha-response': string;
}

export interface RecaptchaWithVersion extends Recaptcha {
  recaptchaVersion: RecaptchaVersion;
}

export interface AccountBalance extends Balance {
  hold: number;
  remainder: number | null;
}

export interface Account {
  id: number;
  accountNumber: number;
  status: AccountStatus;
  isTrusted: boolean;
  shopTransferEnabled: boolean;
  authOperations: { [T in TwoFactorAction]: TwoFactorType };
  authMethods: { [T in TwoFactorType]: boolean };
  email: string;
  birthdate: string;
  ipList: string[];
  defaultLang: string;
  timezone: string;
  useApi: boolean;
  useInfodesk: boolean;
  verificationMethod: VerificationMethod;
  phone: string | null;
  telegramChatId: string | null;

  balances: AccountBalance[];
}

interface AccountParams extends Account {
  authOperations: any;
  authMethods: any;
}

export interface AuthAddOns {
  encodedBillId?: string;
}

export interface LoginCredentials {
  email: string;
  password: string;
}

export type LoginCredentialsWithRecaptcha = LoginCredentials &
  RecaptchaWithVersion;

export interface RegisterForm extends RecaptchaWithVersion {
  email: string;
  password: string;
  agreement: boolean;
  lang: string;
}

export interface InitPassRecoveryForm extends RecaptchaWithVersion {
  email: string;
}

export interface PassRecoveryForm {
  password: string;
}

export interface RegistrationConfirmation {
  email: string;
}

export interface RegistrationConfirmationForm {
  password: string;
}

export interface CodeRequiredForm {
  code: string;
}

export const isAuthenticated = factory((r) => () => r<boolean>('get', '/auth'));

export const account = factory(
  (r) =>
    <T extends APIParams<AccountParams>>(params: T[]) =>
      r<PeekAPIParams<Account, T>>('get', '/account', {
        param: constructParams(params),
      })
);

export const register = factory(
  (r) => (form: RegisterForm, addOns?: AuthAddOns) =>
    r<string>(
      'post',
      '/registration/code',
      snakify(addOns ? { ...form, addOns } : form)
    )
);

export const login = factory(
  (r) => (credentials: LoginCredentialsWithRecaptcha, addOns?: AuthAddOns) =>
    r<null>(
      'post',
      '/login',
      snakify(addOns ? { ...credentials, addOns } : credentials)
    )
);

export const twoFactorLogin = factory(
  (r) =>
    (
      credentials: LoginCredentials,
      addOns?: AuthAddOns,
      twoFactor?: TwoFactor
    ) =>
      r<null>(
        'post',
        '/two_factor_login',
        snakify(
          addOns
            ? { ...credentials, ...twoFactor, addOns }
            : { ...credentials, ...twoFactor }
        )
      )
);

export const codeRequiredLogin = factory(
  (r) =>
    (
      credentials: LoginCredentials,
      codeRequired: CodeRequiredForm,
      addOns?: AuthAddOns
    ) =>
      r<null>(
        'post',
        '/login/code',
        snakify(
          addOns
            ? { ...credentials, ...codeRequired, addOns }
            : { ...credentials, ...codeRequired }
        )
      )
);

export const resendCode = factory(
  (r) => (guid: string, recaptcha: Recaptcha) =>
    r<null>('post', `/login/code/${guid}/notify`, snakify(recaptcha))
);

export const logout = factory((r) => () => r<null>('post', '/logout', {}));

export const feedback = factory(
  (r) => (message: string) => r<null>('post', '/feedback', { message })
);

export const checkTwoFactor = factory(
  (r) => (action: TwoFactorAction, data: object) =>
    r<boolean>('post', `/auth/operations/${action}/verify`, data)
);

export const verifyToken = factory(
  (r) => (type: TokenType, token: string) =>
    r<{ email: string; attempts?: number }>('get', `/token/${type}/${token}`)
);

export const registrationNotify = factory(
  (r) => (token: string, recaptcha: Recaptcha) =>
    r<null>('post', `/registration/${token}/notify`, snakify(recaptcha))
);

export const registrationNotifyCode = factory(
  (r) => (token: string, recaptcha: Recaptcha) =>
    r<null>('post', `/registration/code/${token}/notify`, snakify(recaptcha))
);

export const recoveryNotify = factory(
  (r) => (token: string, recaptcha: Recaptcha) =>
    r<null>('post', `/recovery/${token}/notify`, snakify(recaptcha))
);

export const recoveryNotifyCode = factory(
  (r) => (token: string, recaptcha: Recaptcha) =>
    r<null>('post', `/recovery/code/${token}/notify`, snakify(recaptcha))
);

export const verifyMail = factory(
  (r) => (token: string) => r<null>('post', `/registration/${token}`, {})
);

export const verifyMailCode = factory(
  (r) => (token: string, code: CodeRequiredForm) =>
    r<null>('post', `/registration/code/${token}`, snakify(code))
);

export const initRecovery = factory(
  (r) => (form: InitPassRecoveryForm) =>
    r<string | number>('post', '/recovery/code', snakify(form))
);

export const processRecovery = factory(
  (r) => (token: string, form: PassRecoveryForm) =>
    r<null>('post', `/recovery/${token}`, snakify(form))
);

export const processRecoveryCode = factory(
  (r) => (token: string, form: PassRecoveryForm) =>
    r<null>('post', `/recovery/code/${token}`, snakify(form))
);

export const getRegistrationConfirmation = factory(
  (r) => (token: string) =>
    r<RegistrationConfirmation>('get', `/registration/confirm/${token}`)
);

export const confirmRegistration = factory(
  (r) => (token: string, form: RegistrationConfirmationForm) =>
    r<null>('post', `/registration/confirm/${token}`, snakify(form))
);
