import React from 'react';
import { useTranslation } from 'react-i18next';

import Loader from 'components/loader';
import { useLanguageChange } from 'hooks/translation';
import { useComponentVisible } from 'hooks/visibility';
import { fallbackLng, languagesInfo } from 'libs/i18n';

import { ScLangDropdown } from './styled';
import { ScShutter } from 'components/shutter/styled';

import { ReactComponent as SphereIcon } from 'imgs/sphere.svg';

export const LanguageDropdown = () => {
  const { i18n } = useTranslation();

  const [changeLanguage, pending] = useLanguageChange();

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  function toggleDropdown() {
    setIsComponentVisible((i) => !i);
  }

  const handleChangeLanguage = (lang: string) => {
    toggleDropdown();
    changeLanguage(lang);
  };

  const currentLgName = languagesInfo[i18n.language]
    ? languagesInfo[i18n.language].name
    : languagesInfo[fallbackLng].name;

  return (
    <ScLangDropdown>
      <Loader on={pending} />

      <div className="header" onClick={toggleDropdown}>
        <SphereIcon />
        <span>{currentLgName}</span>
      </div>
      {isComponentVisible && (
        <ul className="menu" ref={ref}>
          {Object.keys(languagesInfo).map((key) => {
            const lg = languagesInfo[key];

            return (
              <li key={lg.code} onClick={() => handleChangeLanguage(lg.code)}>
                {lg.name}
              </li>
            );
          })}
        </ul>
      )}
      {isComponentVisible && <ScShutter />}
    </ScLangDropdown>
  );
};
