import React, { FC, useContext, useMemo } from 'react';
import { AccountContext } from 'modules/accountContext';
import { ScAvatar } from './styled';
import { useTheme } from 'hooks/theme';
import { AccountStatus } from 'helpers/enums';

export const Avatar: FC = () => {
  const { email, status } = useContext(AccountContext);

  const { userAvatarGradient } = useTheme();

  const { fistLetter, firstTwoLetters } = useMemo(
    () => ({
      fistLetter: email.slice(0, 1),
      firstTwoLetters: email.slice(0, 2),
    }),
    [email]
  );

  const gradient = useMemo(() => {
    switch (true) {
      case status === AccountStatus.EMAIL_VERIFIED ||
        status === AccountStatus.VERIFYING:
        return userAvatarGradient[7];
      case /^[A-Da-d0-1]$/.test(fistLetter):
        return userAvatarGradient[0];
      case /^[E-He-h2-3]$/.test(fistLetter):
        return userAvatarGradient[1];
      case /^[I-Li-l3-4]$/.test(fistLetter):
        return userAvatarGradient[2];
      case /^[M-Pm-p5-6]$/.test(fistLetter):
        return userAvatarGradient[3];
      case /^[Q-Tq-t7-8]$/.test(fistLetter):
        return userAvatarGradient[4];
      case /^[U-Xu-x8-9]$/.test(fistLetter):
        return userAvatarGradient[5];
      case /^[X-Zx-z]$/.test(fistLetter):
        return userAvatarGradient[6];
      default:
        return userAvatarGradient[0];
    }
  }, [fistLetter, status, userAvatarGradient]);

  return (
    <ScAvatar gradient={gradient} firstTwoLetters={firstTwoLetters}>
      <div>{firstTwoLetters}</div>
    </ScAvatar>
  );
};
