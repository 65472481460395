import { useCallback, useContext } from 'react';
import { format } from 'date-fns';
import { OptionsWithTZ, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';

import { getLocale } from 'helpers/date';
import { Omit } from 'types/common';

import { AccountContext } from './index';

export function useUTCToAccountTimezone() {
  const { timezone } = useContext(AccountContext);

  return useCallback(
    (date: Date | number | string) => utcToZonedTime(date, timezone),
    [timezone]
  );
}

export function useAccountTimezoneToUTC() {
  const { timezone } = useContext(AccountContext);

  return useCallback(
    (date: Date | number | string) => zonedTimeToUtc(date, timezone),
    [timezone]
  );
}

export function useDateFormat() {
  const utcToAccountTimezone = useUTCToAccountTimezone();

  const {
    i18n: { language },
  } = useTranslation();

  const locale = getLocale(language);

  return useCallback(
    (
      date: Date | string | number,
      fmt: string,
      options?: Omit<OptionsWithTZ, 'timeZone' | 'locale'>
    ) => format(utcToAccountTimezone(date), fmt, { ...options, locale }),
    [locale, utcToAccountTimezone]
  );
}
