import React, { FC, forwardRef, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ScBlock,
  ScBlockBody,
  ScBlockBodyExpanded,
  ScBlockBodyTitle,
  ScBlockBodyTitleExpanded,
  ScBlockHeader,
  ScBlockSection,
  ScBlockSectionBody,
  ScBlockSectionHeader,
  ScBlockWithCaptcha,
  ScButtonClose,
  ScErrorBlock,
  ScLabeledBlock,
  ScOnReviewBlock,
  ScWrap,
} from 'components/block/styled';
import { ScButtonLink } from 'components/button/styled';
import { capitalize } from 'helpers/common';
import { ReactComponent as WarningIcon } from 'imgs/warning-red-icon.svg';
import { Button } from 'components/button';

interface BlockProps {
  header?: React.ReactNode;
  marginTop?: true;
  small?: boolean;
  center?: boolean;
  locked?: boolean;
  verticalAlign?: boolean;
  onClose?: () => void;
}

export const Block = forwardRef<HTMLDivElement, PropsWithChildren<BlockProps>>(
  ({ children, header, onClose, ...rest }, ref) => (
    <ScBlock ref={ref} {...rest}>
      {header ? (
        <ScBlockHeader withButtonClose={onClose != null}>
          <h2>{header}</h2>
          {onClose && <ScButtonClose onClick={onClose} />}
        </ScBlockHeader>
      ) : null}
      {children}
    </ScBlock>
  )
);

interface BlockBodyProps {
  header?: React.ReactNode;
  expanded?: boolean;
}

export const BlockBody: FC<PropsWithChildren<BlockBodyProps>> = ({
  children,
  header,
  expanded,
}) =>
  expanded ? (
    <ScBlockBodyExpanded>
      {header && <ScBlockBodyTitleExpanded>{header}</ScBlockBodyTitleExpanded>}
      {children}
    </ScBlockBodyExpanded>
  ) : (
    <ScBlockBody>
      {header && <ScBlockBodyTitle>{header}</ScBlockBodyTitle>}
      {children}
    </ScBlockBody>
  );

export const BlockFooter: FC<PropsWithChildren<any>> = ({ children }) => (
  <ScWrap topPadding="0">{children}</ScWrap>
);

export const BlockSection: FC<PropsWithChildren<any>> = ({ children }) => (
  <ScBlockSection>{children}</ScBlockSection>
);

interface BlockSectionHeaderProps {
  titleIcon?: any;
  title?: string;
}

export const BlockSectionHeader: FC<
  PropsWithChildren<BlockSectionHeaderProps>
> = ({ children, titleIcon, title }) => (
  <ScBlockSectionHeader>
    <h2>
      {titleIcon ? <img alt="" src={titleIcon} /> : null}
      {title}
    </h2>
    <div> {children}</div>
  </ScBlockSectionHeader>
);

export const BlockSectionBody: FC<PropsWithChildren<any>> = ({ children }) => (
  <ScBlockSectionBody>{children}</ScBlockSectionBody>
);

export interface ErrorBlockProps {
  withReload?: boolean;
  marginTop?: true;
}

export const ErrorBlock: FC<PropsWithChildren<ErrorBlockProps>> = ({
  withReload = false,
  children,
  marginTop,
}) => {
  const { t } = useTranslation('common');

  return (
    <ScErrorBlock marginTop={marginTop}>
      <div>
        <WarningIcon />
      </div>
      <div className="error-text">
        <span>{children} </span>
        {withReload ? (
          <ScButtonLink onClick={() => window.location.reload()}>
            {capitalize(t('common:reload'))}
          </ScButtonLink>
        ) : null}
      </div>
    </ScErrorBlock>
  );
};

interface OnReviewBlockProps {
  title: string;
  text?: string;
  image?: string;
  button?: {
    text: string;
    to: string;
  };
}

export const OnReviewBlock: FC<OnReviewBlockProps> = ({
  image,
  title,
  text,
  button,
}) => {
  const { t } = useTranslation('common');

  return (
    <>
      <ScLabeledBlock noBorder>
        <ScOnReviewBlock>
          {image && (
            <div className="ico">
              <img src={image} alt="" />
            </div>
          )}
          <div className="message">
            <h3>{title}</h3>
            {text && <p>{text}</p>}
          </div>
        </ScOnReviewBlock>
      </ScLabeledBlock>
      <ScWrap topPadding="0">
        <Button primary to={button ? button.to : '/'}>
          {button ? button.text : t(`backHomePage`)}
        </Button>
      </ScWrap>
    </>
  );
};

export const BlockWithCaptcha: FC<
  PropsWithChildren<
    BlockProps & {
      captcha?: React.ReactNode;
    }
  >
> = ({ children, captcha, ...props }) => {
  return (
    <ScBlockWithCaptcha>
      <Block {...props}>{children}</Block>
      {captcha}
    </ScBlockWithCaptcha>
  );
};
