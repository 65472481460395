import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { hideParamsFromUrl } from './utils';
import { SENTRY_CAT } from './consts';

const MARKER = '***-HIDDEN-***';
const SECURITY_URL = [
  `/auth/mail/${MARKER}/verify-code`,
  `/en/bill/pay/${MARKER}`,
  `/ru/bill/pay/${MARKER}`,
  `/personal/bill/${MARKER}`,
  `/bill/pay/${MARKER}?lng=ru`,
  `/api/bills/${MARKER}/authenticated`,
  `/api/bills/${MARKER}/anonymous`,
];

try {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    release: APP_VERSION,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    debug: false,
    beforeSend(e) {
      if (e.request && e.request.url) {
        e.request.url = hideParamsFromUrl(e.request.url, MARKER, SECURITY_URL);
      }
      if (e.exception && e.exception.values) {
        e.exception.values = e.exception.values.map((value) => {
          if (value.stacktrace && value.stacktrace.frames) {
            return {
              ...value,
              stacktrace: {
                ...value.stacktrace,
                frames: value.stacktrace.frames.map((frame) => {
                  if (!frame.filename) return frame;
                  return {
                    ...frame,
                    filename: hideParamsFromUrl(
                      frame.filename,
                      MARKER,
                      SECURITY_URL
                    ),
                  };
                }),
              },
            };
          }
          return value;
        });
      }
      return e;
    },
    beforeBreadcrumb(breadcrumb, _hint) {
      if (breadcrumb.category === SENTRY_CAT.navigation && breadcrumb.data) {
        breadcrumb.data = {
          ...breadcrumb.data,
          from: hideParamsFromUrl(breadcrumb.data.from, MARKER, SECURITY_URL),
          to: hideParamsFromUrl(breadcrumb.data.to, MARKER, SECURITY_URL),
        };
      }
      if (breadcrumb.category === SENTRY_CAT.xhr && breadcrumb.data) {
        breadcrumb.data = {
          ...breadcrumb.data,
          url: hideParamsFromUrl(breadcrumb.data.url, MARKER, SECURITY_URL),
        };
      }
      return breadcrumb;
    },
  });
} catch (e) {
  console.error('Sentry: ', e);
}
