import styled, { css } from 'styled-components/macro';

import { TOOLTIP_REMOVE_TIMEOUT } from 'components/tooltip/utils';
import {
  AnimationHideKeyframes,
  AnimationTopHideKeyframes,
} from 'components/global/Animations';
import { device } from 'components/global/MediaQuery';

export const AnimationTopHide = css`
  opacity: 0;
  transition: 1s ease;
  animation: ${AnimationTopHideKeyframes} ${TOOLTIP_REMOVE_TIMEOUT}ms ease;
`;

export const AnimationHide = css`
  opacity: 0;
  transition: 1s ease;
  animation: ${AnimationHideKeyframes} ${TOOLTIP_REMOVE_TIMEOUT}ms ease;
`;

export interface ScTooltipProps {
  warning?: boolean;
  info?: boolean;
  success?: boolean;
  error?: boolean;
  animate?: boolean;
  inline?: boolean;
}

export const ScTooltip = styled.div<ScTooltipProps>`
  position: relative;
  font-size: 16px;
  white-space: pre-wrap;
  box-sizing: border-box;
  padding: 22px 20px 22px 60px;
  font-weight: 400;
  border-radius: 0 12px 12px 0;
  border: 1px solid ${({ theme }) => theme.dividersColor};
  color: ${({ theme }) => theme.textColorPrimary};
  text-transform: none;
  background: url(${({ info, error, warning, success, theme }) =>
      info
        ? theme.infoIcon
        : error
        ? theme.errorIcon
        : warning
        ? theme.warningIcon
        : success
        ? theme.successIcon
        : ''})
    no-repeat 24px 50% ${({ theme }) => theme.backgroundColorBlock};
  &:before {
    content: '';
    position: absolute;
    width: 4px;
    height: 100%;
    background: ${({ info, error, warning, success, theme }) =>
      info
        ? theme.info
        : error
        ? theme.error
        : warning
        ? theme.warning
        : success
        ? theme.success
        : ''};
    z-index: 5;
    left: 0;
    top: 0;
  }
`;

export const ScTooltipGlobal = styled(ScTooltip)`
  position: fixed;
  bottom: 20px;
  right: 10px;
  left: auto;
  top: auto;
  z-index: 1111;
  ${({ animate }) => (animate ? AnimationHide : null)};
  &:before {
    @media (${device.desktop}) {
      border-radius: 0;
    }
  }
  @media (${device.desktop}) {
    ${({ animate }) => (animate ? AnimationTopHide : null)};
    border-radius: 0;
    bottom: auto;
    right: auto;
    top: 0;
    left: 0;
    width: 100vw;
  }
`;
