import styled from 'styled-components/macro';
import { device } from 'components/global/MediaQuery';

export const ScMobileMenuHeight = '70px';

export const ScMobileSideBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${ScMobileMenuHeight};
  background: ${({ theme }) => theme.backgroundColorSecondary};
  box-shadow: ${({ theme }) => theme.mobileMenuShadow};
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 110;
  box-sizing: border-box;
  .sidebar-mobile-link {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    margin: 20px;
    align-items: center;
    color: ${({ theme }) => theme.textColorSecondary};
    background: none;
    border: 0;
    box-sizing: border-box;
    padding: 0;
    cursor: pointer;
    font-size: 10px;
    letter-spacing: 0.12px;
    @media (${device.mobileS}) {
      margin: 7px;
    }
    &.active {
      color: ${({ theme }) => theme.mainColor} !important;
      svg *[class$='svg-fill'] {
        fill: ${({ theme }) => theme.mainColor};
      }
      svg *[class$='svg-stroke'] {
        stroke: ${({ theme }) => theme.mainColor};
      }
    }
    svg {
      margin: 0 auto 5px auto;
      *[class$='svg-fill'] {
        fill: ${({ theme }) => theme.textColorSecondary};
      }
      *[class$='svg-stroke'] {
        stroke: ${({ theme }) => theme.textColorSecondary};
      }
    }
    &:hover {
      text-decoration: none;
    }
  }
`;

export const ScMoreBlock = styled.div`
  position: absolute;
  z-index: 99;
  background: ${({ theme }) => theme.backgroundColorSecondary};
  width: 100%;
  height: auto;
  max-height: calc(100vh - ${ScMobileMenuHeight});
  bottom: ${ScMobileMenuHeight};
  padding: 30px 30px 50px 30px;
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.mobileMoreBlockShadow};

  border-radius: 8px 8px 0 0;
  @media (max-height: 471px) {
    padding: 10px 30px 30px 30px;
  }
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 24px;
    font-weight: 300;
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    color: ${({ theme }) => theme.textColorPrimary};
  }
  hr {
    margin: 12px 0 12px auto;
    border: 0;
    border-top: 1px solid ${({ theme }) => theme.dividersColor};
    @media (max-height: 471px) {
      margin: 5px 0 5px 0;
    }
  }
  .header-links {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    @media (max-height: 471px) {
      margin-bottom: 15px;
    }
    a,
    button {
      color: ${({ theme }) => theme.textColorCaption};
      margin-bottom: 25px;
      text-align: left;
      font-size: 14px;
      @media (max-height: 471px) {
        margin-bottom: 15px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .sidebar-mobile-link {
    flex-direction: row;
    justify-content: left;
    margin: 25px auto;
    width: auto;
    @media (max-height: 471px) {
      margin: 15px auto;
    }
    span {
      font-size: 16px;
      @media (max-height: 471px) {
        font-size: 14px;
      }
    }
    svg {
      margin: 0 5px 0 0;
    }
  }
  .lang-bar {
    margin-top: 25px;
    span {
      color: ${({ theme }) => theme.textColorCaption};
      margin-right: 25px;
      text-transform: uppercase;
      font-weight: 500;
      &.active {
        color: ${({ theme }) => theme.textColorPrimary};
      }
      &:last-child {
        margin-right: 0;
      }
    }
    @media (max-height: 471px) {
      margin-top: 10px;
    }
  }
`;
