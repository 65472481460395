import createStore from 'zustand';

import { immer } from 'helpers/zustand';

export interface AuthStoreState {
  encodedBillId: string | undefined;
}

export interface AuthStoreActions {
  setEncodedBillId: (value: string | undefined) => void;

  reset: () => void;
}

export const initialStoreState = {
  encodedBillId: undefined,
};

export const useAuthStore = createStore(
  immer<AuthStoreState & AuthStoreActions>((set) => ({
    ...initialStoreState,

    setEncodedBillId: (value) =>
      set((state) => void (state.encodedBillId = value)),

    reset: () => set(() => initialStoreState),
  }))
);

export function resetAuthStore() {
  const { reset } = useAuthStore.getState();

  reset();
}
