const OMNI_KEY = process.env.REACT_APP_OMNY_KEY;

const widgetScript = (key: string) => `
  !(function (e, o) {
    !window.omni ? (window.omni = []) : '';
    window.omni.push(o);
    o.g_config = { widget_id: '${key}' };
    o.email_widget = o.email_widget || {};
    var w = o.email_widget;
    w.readyQueue = [];
    o.config = function (e) {
      this.g_config.user = e;
    };
    w.ready = function (e) {
      this.readyQueue.push(e);
    };
    var r = e.getElementsByTagName('script')[0];
    c = e.createElement('script');
    (c.type = 'text/javascript'), (c.async = !0);
    c.src = 'https://omnidesk.pro/bundles/acmesite/js/cwidget0.2.min.js';
    r.parentNode.insertBefore(c, r);
  })(document, []);
`;

export const omniWidget = {
  key: OMNI_KEY,
  add: function () {
    if (this.key == null) return;

    const script = document.createElement('script');
    script.id = `script_${this.key}`;
    script.async = true;
    script.innerHTML = widgetScript(this.key);
    document.head.appendChild(script);
  },
  remove: function () {
    const elements = [
      document.getElementById(`omni_widget_iframe_${this.key}`),
      document.getElementById(`omni_widget_iframe_widget_${this.key}`),
      document.getElementById(`script_${this.key}`),
      document.getElementById(`cwidget_${this.key}`),
      document.getElementById(`omni_widget_hint`),
      document.getElementById(`omni_widget_tip`),
    ];

    elements.forEach((element) => {
      if (element != null) {
        element.remove();
      }
    });

    // @ts-ignore
    if (window.omni != null) {
      // @ts-ignore
      window.omni = undefined;
    }

    // @ts-ignore
    if (window.OmniWidgetApi != null) {
      // @ts-ignore
      window.OmniWidgetApi = undefined;
    }

    // @ts-ignore
    if (!window.ODW) {
      // @ts-ignore
      window.ODW = undefined;
    }

    // @ts-ignore
    if (window.b_omni_loaded != null) {
      // @ts-ignore
      window.b_omni_loaded = undefined;
    }
  },
};
