import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-GB';
import uk from 'date-fns/locale/uk';
import pl from 'date-fns/locale/pl';

export type MonthFormat = 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
export type WeekdayFormat = 'long' | 'short' | 'narrow';

export function getLocale(code: string) {
  switch (code) {
    case 'ru':
      return ru;
    case 'en':
      return en;
    case 'uk':
      return uk;
    case 'pl':
      return pl;
    default:
      return ru;
  }
}

export function getMonthName(
  locale: string,
  month: number,
  format: MonthFormat = 'long'
) {
  return new Date(2000, month).toLocaleDateString(locale, { month: format });
}

export function getWeekDays(locale: string, format: WeekdayFormat = 'long') {
  const d = new Date();

  d.setDate(1);

  // Get the first Monday in the month
  while (d.getDay() !== 1) {
    d.setDate(d.getDate() + 1);
  }

  const weekdays: string[] = [];
  for (let i = 0; i < 7; i++) {
    weekdays.push(d.toLocaleDateString(locale, { weekday: format }));
    d.setDate(d.getDate() + 1);
  }

  return weekdays;
}

export function getMonths(locale: string, format: MonthFormat = 'long') {
  const months: string[] = [];
  for (let i = 0; i < 12; i++) {
    months.push(
      new Date(2000, i).toLocaleDateString(locale, { month: format })
    );
  }

  return months;
}

export enum Quarters {
  FIRST,
  SECOND,
  THIRD,
  FORTH,
}

export function getMonthQuarter(month: number): Quarters | undefined {
  if (month <= 2) {
    return Quarters.FIRST;
  } else if (month <= 5) {
    return Quarters.SECOND;
  } else if (month <= 8) {
    return Quarters.THIRD;
  } else if (month <= 11) {
    return Quarters.FORTH;
  }
  return undefined;
}

export function getQuarterMonths(quarter: Quarters): [number, number] {
  let startMonth: number = 0;
  let endMonth: number = 0;
  switch (quarter) {
    case Quarters.FIRST:
      startMonth = 0;
      endMonth = 2;
      break;
    case Quarters.SECOND:
      startMonth = 3;
      endMonth = 5;
      break;
    case Quarters.THIRD:
      startMonth = 6;
      endMonth = 8;
      break;
    case Quarters.FORTH:
      startMonth = 9;
      endMonth = 11;
      break;
  }

  return [startMonth, endMonth];
}

export function getPastDayStart(days: number) {
  const now = new Date();

  return Date.UTC(now.getFullYear(), now.getMonth(), now.getDate() - days);
}
