import { css } from 'styled-components/macro';

export const MTextOverflow = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const MCoverBlock = css`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;
