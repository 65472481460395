import { HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import { rotate, ScBlinkDotAnimation } from 'components/global/Animations';

export interface ScLoaderProps extends HTMLAttributes<HTMLDivElement> {
  inline?: boolean;
  small?: boolean;
  center?: boolean;
  background?: boolean;
}

export const ScLoaderInline = styled.div`
  top: 0;
  right: 55px;
  position: absolute;
  z-index: 9;
  padding: 26px 0;
  img {
    width: 18px;
    height: 18px;
    animation: ${rotate} 1s infinite;
  }
`;

export const ScLoader = styled.div<ScLoaderProps>`
  //instead of adding !important to override properties
  &&&{
    width: 100%;
  }
  position: ${({ inline }) => (inline ? 'absolute' : 'fixed')};
  text-align: center;
  top: ${({ center, small }) =>
    center ? `calc(50% + ${small ? 10 : 20}px);` : '0;'}
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 102;
  border-radius: 12px;
  ${({ background = true, theme }) =>
    background ? `background: ${theme.overlayBg};` : ''}

  img {
    width: ${({ small }) => (small ? 20 : 40)}px;
    animation: ${rotate} 2s linear infinite;
    z-index: 98;
  }
`;

export const ScLoadingText = styled.div`
  color: #000;
  animation: ${ScBlinkDotAnimation} 1s infinite;
`;

export const ScLoaderWrapper = styled.div`
  position: relative;
`;
