import React, { FC } from 'react';

import { useCurrentMediaType } from 'hooks/responsive';
import { enumValues } from 'helpers/common';
import { MediaType } from 'helpers/enums';

export interface ResponsiveProps {
  mobile?: React.ReactNode | MediaType;
  tablet?: React.ReactNode | MediaType;
  desktop?: React.ReactNode | MediaType;
}

function isMediaType(x: any): x is MediaType {
  return typeof x === 'number' && enumValues(MediaType).includes(x);
}

export const Responsive: FC<ResponsiveProps> = ({
  mobile,
  tablet,
  desktop,
}) => {
  const currentType = useCurrentMediaType();

  if (typeof currentType === 'undefined') return null;

  function getComponentByType(type: MediaType) {
    switch (type) {
      case MediaType.DESKTOP:
        return desktop;
      case MediaType.TABLET:
        return tablet;
      case MediaType.MOBILE:
        return mobile;
    }
  }

  let Component = getComponentByType(currentType);

  // you can pass for example tablet={MediaType.MOBILE}
  // and for tablet media mobile component will be used
  if (isMediaType(Component)) {
    Component = getComponentByType(Component);
  }

  return <>{Component}</>;
};
