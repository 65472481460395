import React, { FC } from 'react';
import * as Sentry from '@sentry/react';
import { ThemeProvider } from 'styled-components';
import { Theme } from 'themes/contracts';
import { GlobalStyles } from 'components/GlobalStyles';
import { InternalErrorBlock } from 'components/internalError';
import { AuthServiceProvider } from 'modules/authService/provider';
import { Routes } from 'router/routes';

console.log(APP_VERSION);

const App: FC<{ theme: Theme }> = ({ theme }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <Sentry.ErrorBoundary
          fallback={
            <section>
              <InternalErrorBlock />
            </section>
          }
        >
          <AuthServiceProvider>
            <Routes />
          </AuthServiceProvider>
        </Sentry.ErrorBoundary>
      </>
    </ThemeProvider>
  );
};

export default Sentry.withProfiler(App);
