import axios, { CancelTokenSource } from 'axios';

import {
  LoginCredentials,
  login,
  twoFactorLogin,
  logout,
  AuthAddOns,
  LoginCredentialsWithRecaptcha,
  codeRequiredLogin,
  CodeRequiredForm,
} from 'api/auth';
import { TwoFactor } from 'api/base';
import { uuid } from 'helpers/common';

import { resetAuthStore } from './store';

export type Subscriber = (isAuthenticated: boolean) => void;

export class AuthService {
  public isAuthenticated: boolean;
  public loginKey: string | undefined;
  private subscribers: Subscriber[];
  private readonly cancelSource: CancelTokenSource;

  constructor(isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated;
    this.subscribers = [];

    this.cancelSource = axios.CancelToken.source();

    this.setLoginKey();
  }

  private setLoginKey = () => {
    if (this.isAuthenticated) {
      this.loginKey = uuid();
    } else {
      this.loginKey = undefined;
    }
  };

  private notify = () => {
    this.subscribers.forEach((subscriber) => subscriber(this.isAuthenticated));
  };

  login = async (
    credentials: LoginCredentialsWithRecaptcha,
    addOns?: AuthAddOns
  ) => {
    const resp = await login.call(
      { cancelToken: this.cancelSource.token },
      credentials,
      addOns
    );

    resetAuthStore();
    this.setIsAuthenticated(true);

    return resp;
  };

  twoFactorLogin = async (
    credentials: LoginCredentials,
    twoFactor: TwoFactor,
    addOns?: AuthAddOns
  ) => {
    const resp = await twoFactorLogin.call(
      { cancelToken: this.cancelSource.token },
      credentials,
      addOns,
      twoFactor
    );

    resetAuthStore();
    this.setIsAuthenticated(true);

    return resp;
  };

  codeRequiredLogin = async (
    credentials: LoginCredentials,
    codeRequired: CodeRequiredForm,
    addOns?: AuthAddOns
  ) => {
    const resp = await codeRequiredLogin.call(
      { cancelToken: this.cancelSource.token },
      credentials,
      codeRequired,
      addOns
    );

    resetAuthStore();
    this.setIsAuthenticated(true);

    return resp;
  };

  logout = async () => {
    const resp = await logout.call({ cancelToken: this.cancelSource.token });

    this.setIsAuthenticated(false);

    return resp;
  };

  setIsAuthenticated = (isAuthenticated: boolean) => {
    this.isAuthenticated = isAuthenticated;

    this.setLoginKey();
    this.notify();
  };

  subscribe = (subscriber: Subscriber, immediateCall?: boolean) => {
    this.subscribers.push(subscriber);

    if (immediateCall) subscriber(this.isAuthenticated);

    return () => {
      const subscriberIndex = this.subscribers.findIndex(
        (c) => c === subscriber
      );

      if (subscriberIndex === -1) return;

      this.subscribers.splice(subscriberIndex, 1);
    };
  };

  cancelRequest = (msg?: string) => {
    this.cancelSource.cancel(msg);
  };
}
