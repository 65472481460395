import React from 'react';
import createStore from 'zustand';

import { immer } from 'helpers/zustand';
import { uuid } from 'helpers/common';
import { TFunction } from 'react-i18next';

export enum TooltipTypes {
  SUCCESS,
  WARNING,
  INFO,
  ERROR,
}

export interface GlobalTooltipState {
  tooltip:
    | {
        id: string;
        type: TooltipTypes;
        body: any;
        animate: boolean;
        translate: boolean;
      }
    | undefined;
}

export interface GlobalTooltipConfig {
  type: TooltipTypes;
  body: React.ReactNode;
  id?: string;
  animate?: boolean;
}

export interface GlobalTooltipConfigWithTranslation {
  type: TooltipTypes;
  body: ((t: TFunction) => string) | string;
  id?: string;
  animate?: boolean;
  translate: boolean;
}

export interface GlobalTooltipActions {
  show(config: GlobalTooltipConfig): void;

  show(config: GlobalTooltipConfigWithTranslation): void;

  show(config: any): void;

  remove: () => void;
}

const useGlobalTooltipStore = createStore(
  immer<GlobalTooltipState & GlobalTooltipActions>((set) => ({
    tooltip: undefined,
    show: ({ type, body, id, animate = true, translate = false }: any) =>
      set((state) => {
        state.tooltip = {
          id: id == null ? uuid() : id,
          type,
          body,
          animate,
          translate,
        };
      }),
    remove: () => set((state) => void (state.tooltip = undefined)),
  }))
);

export { useGlobalTooltipStore };
