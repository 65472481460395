import React, { FC, PropsWithChildren } from 'react';
import { Link, useViewElement } from 'react-navi';
import {
  map,
  Matcher,
  redirect,
  route,
  withContext,
  withView,
  Resolvable,
  ResRoute,
} from 'navi';
import i18n from 'i18next';
import {
  I18nextProviderProps,
  TFunction,
  Trans,
  Translation,
} from 'react-i18next';

import i18nInitialization, { i18nInstance } from 'libs/i18n';
import { AuthenticatedRouteContext, GlobalRouteContext } from 'types/routes';
import { AccountStatus } from 'helpers/enums';
import { hasLoadedNamespace, loadNamespaces } from 'helpers/i18n';
import { capitalize, checkUserIsVerifiedOrTrusted } from 'helpers/common';
import { TranslationMapping } from 'helpers/error';
import { ErrorBlock } from 'components/block';
import { errorTranslationFactory, translationFactory } from 'helpers/trans';
import { parseErrorCode } from 'helpers/api';

export const ResolveChildView = ({
  children,
}: {
  children: (view: React.ReactNode) => JSX.Element;
}) => {
  const el = useViewElement();

  if (el == null) {
    return null;
  }

  return children(el);
};

export function withResolvedView(Component: FC<PropsWithChildren<any>>) {
  return withView(
    <ResolveChildView>
      {(view) => <Component>{view}</Component>}
    </ResolveChildView>
  );
}

export function loginRedirect(next: string, query?: string) {
  let search = `next=${encodeURIComponent(next)}`;

  if (query) {
    search += '&' + query;
  }

  return redirect(`/auth/login/?${search}`, { exact: false });
}

export function withAuthentication<Context extends GlobalRouteContext>(
  matcher: Matcher<any>
) {
  return map<Context>((request, context) =>
    context.userAuthenticated
      ? matcher
      : loginRedirect(
          request.path + request.search,
          request.query.lng != null ? `lng=${request.query.lng}` : undefined
        )
  );
}

export function withAccountStatus(
  statuses: AccountStatus[],
  onMatch: Matcher<any>,
  onReject: Matcher<any>
): Matcher<any> {
  return map<AuthenticatedRouteContext>((_request, context) => {
    if (!context.account || !context.userAuthenticated) {
      throw new Error('requires account context and authenticated user');
    }

    return statuses.includes(context.account.status) ? onMatch : onReject;
  });
}

export function withActiveAccount(
  matcher: Matcher<any>,
  block?: React.ComponentType<any>
) {
  // workaround circular imports
  const Block = block || require('components/block').ErrorBlock;

  return withAccountStatus(
    [
      AccountStatus.EMAIL_VERIFIED,
      AccountStatus.VERIFIED,
      AccountStatus.VERIFYING,
    ],
    matcher,
    route({
      title: titleGetter('common:accountError.activateAccount', {
        format: capitalize,
      }),
      view: (
        <Translation>
          {(t) => (
            <Block>
              {t('common:accountError.contactSupport')}{' '}
              <a href={`mailto:${process.env.REACT_APP_SUPPORT_MAIL}`}>
                {process.env.REACT_APP_SUPPORT_MAIL}
              </a>
            </Block>
          )}
        </Translation>
      ),
    })
  );
}

export function withVerifiedOrTrustedAccount(
  matcher: Matcher<any>,
  block?: React.ComponentType<any>
) {
  // workaround circular imports
  const Block = block || require('components/block').ErrorBlock;

  return map<AuthenticatedRouteContext>((_request, context) => {
    if (!context.account || !context.userAuthenticated) {
      throw new Error('requires account context and authenticated user');
    }

    const { status, isTrusted } = context.account;

    return checkUserIsVerifiedOrTrusted(status, isTrusted)
      ? matcher
      : route({
          title: titleGetter('common:accountError.verifyAccount', {
            format: capitalize,
          }),
          view: (
            <Translation>
              {() => (
                <Block>
                  <Trans i18nKey="common:accountError.verifyToAccess">
                    To access this functionality, please,
                    <Link href="/personal/verification">
                      verify your account
                    </Link>
                  </Trans>
                </Block>
              )}
            </Translation>
          ),
        });
  });
}

export function withSectionView() {
  return withResolvedView(({ children }) => <section>{children}</section>);
}

export interface RouteTranslationContext {
  t: TFunction;
  i18n: I18nextProviderProps['i18n'];
}
export function withTranslation<ParentContext extends object = any>(
  ns: string | string[]
) {
  const namespaces = typeof ns === 'string' ? [ns] : ns;

  return withContext<ParentContext, RouteTranslationContext>(
    async (_request, parentContext) => {
      const ready =
        i18nInstance &&
        (i18nInstance.isInitialized ||
          (i18nInstance as any).initializedStoreOnce) &&
        namespaces.every((n) => hasLoadedNamespace(n));

      if (!ready) {
        await i18nInitialization;
        await new Promise((resolve) => loadNamespaces(ns, resolve));
      }

      return {
        ...parentContext,
        t: i18n.getFixedT(null, namespaces[0]),
        i18n: i18nInstance,
      };
    }
  );
}

export const titleGetter = translationFactory;

export interface RouteErrorViewOptions {
  block?: (error: string) => React.ReactElement | null;
  def?: string | string[];
}

export function routeErrorView(
  errorCode: number,
  mapping: TranslationMapping = {},
  { block, def }: RouteErrorViewOptions = {}
) {
  const translateError = errorTranslationFactory(
    errorCode,
    mapping,
    def || 'common:serverError.download'
  );

  return {
    title: translateError,
    view: (
      <Translation>
        {(t) => {
          const error = translateError(t);

          if (block) {
            return block(error);
          }

          return <ErrorBlock withReload>{error}</ErrorBlock>;
        }}
      </Translation>
    ),
  };
}

export function routeWithRequest<
  Context extends object,
  Data extends object = any
>(
  handler: Resolvable<ResRoute<Data>, Context>,
  onError: {
    mapping?: TranslationMapping;
    options?: RouteErrorViewOptions;
  } = {}
) {
  return route<Context, Data>(async (...args) => {
    try {
      return await handler(...args);
    } catch (e) {
      return routeErrorView(
        parseErrorCode(e),
        onError.mapping,
        onError.options
      );
    }
  });
}
