import { rotate, ScBlockShowAnimation } from 'components/global/Animations';
import { createStyledObject } from 'components/global/createStyledObject';
import { device } from 'components/global/MediaQuery';
import { MTextOverflow } from 'components/global/mixins/all';
import { ScMobileMenuHeight } from 'components/mobileMenu/styled';
import checkbox from 'imgs/checkbox-checked-icon.svg';
import styled from 'styled-components/macro';

export const ScDropdownCover = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  z-index: 13;
  width: 100vw;
  height: 100vh;
`;

export interface ScTextAreaProps {
  maxHeight?: number;
}

export const ScTextArea = styled.textarea<ScTextAreaProps>`
  background: ${({ theme }) => theme.backgroundColorSecondary};
  border: 2px solid ${({ theme }) => theme.mainColor};
  border-radius: 6px;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  line-height: 16px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.textColorPrimary};
  ${({ maxHeight }) =>
    maxHeight != null ? `max-height: ${maxHeight}px;` : null};
`;

export interface ScInputBlockProps {
  disabled?: boolean;
  fullSize?: boolean;
  error?: boolean;
  small?: boolean;
  prefixLength?: number;
}

export const ScInputBlock = styled.div<ScInputBlockProps>`
  --inputPadding: 16px;

  ${({ small }) => (small ? 'width: 294px' : 'width: 100%;')};
  position: relative;
  display: flex;
  flex-wrap: wrap;

  &.dirty {
    .input__prefix {
      opacity: 1;
    }
  }
  .input {
    border-radius: 6px;
    border: 1px solid
      ${({ theme, disabled }) =>
        disabled ? 'transparent' : theme.dividersColor};
    color: ${({ theme }) => theme.textColorPrimary};
    background-color: ${({ theme }) => theme.inputBgColor};
    ${({ disabled, theme }) =>
      disabled
        ? `cursor: not-allowed; color: ${theme.textColorCaption};`
        : null};
    ${({ theme, error }) => (error ? `border-color: ${theme.error}` : null)};
    padding: var(--inputPadding);
    ${({ prefixLength }) =>
      prefixLength != null && prefixLength !== 0
        ? `padding-left: ${10 * prefixLength}px`
        : null};
    font-size: 16px;
    line-height: 16px;
    width: 100%;
    box-sizing: border-box;
    transition-duration: 0.3s;
    text-overflow: ellipsis;
    ::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: ${({ theme }) => theme.textColorSecondary};
      padding-right: 20px;
    }
    &__label {
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 8px;
      color: ${({ theme, disabled }) =>
        disabled ? theme.disabled : theme.inputLabelColor};
    }
    &__prefix {
      position: absolute;
      left: 0;
      top: 1px;
      font-size: 16px;
      opacity: 0;
      transition: all 0.3s ease;
      pointer-events: none;
    }
    &__loader {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      background: ${({ theme }) => theme.backgroundColorBlock}CC;
      z-index: 2;
      img {
        width: 24px;
        height: 24px;
        margin: auto;
        animation: ${rotate} cubic-bezier(0.18, 0.18, 0.85, 0.85) 3s infinite;
      }
    }
    &__error {
      margin-top: 4px;
      color: ${({ theme }) => theme.errorTipTextColor};
      cursor: default;
      display: inline-flex;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      box-sizing: border-box;
      animation: ${ScBlockShowAnimation} 0.3s ease-in;
      user-select: none;
      & > svg {
        margin-right: 8px;
        flex-shrink: 0;
      }
    }
    &:hover {
      background-color: ${({ disabled, theme }) =>
        disabled ? theme.inputBgColor : theme.inputBgColorHover};
    }
    &:focus {
      border-color: ${({ theme }) => theme.mainColor};
      & ~ .input__prefix {
        opacity: 1;
        color: ${({ theme }) => theme.mainColor};
      }
    }
    &__tooltip {
      color: ${({ theme }) => theme.textColorSecondary};
      font-size: 12px;
      line-height: 16px;
      padding-top: 4px;
      width: 100%;
      span {
        display: block;
      }
    }
    &__addons {
      position: absolute;
      right: var(--inputPadding);
      top: var(--inputPadding);
      box-sizing: border-box;
      ${({ disabled }) => (disabled ? 'cursor: not-allowed;' : null)}
      svg {
        path {
          fill: ${({ theme, disabled }) =>
            disabled ? '#B8BEC6' : theme.mainColor};
        }
      }
      .select-button {
        background-color: transparent;
        border-color: transparent;
        padding: 0;
        &:hover {
          background-color: transparent;
        }
      }
      .input__addons--btn {
        padding: 0;
        justify-content: space-between;
        cursor: pointer;
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
`;

export const ScInputSimple = styled.input`
  width: 100%;
  height: 36px;
  border: 1px solid #e3e3e5;
  border-radius: 4px;
  padding: 0 16px;
  font-size: 16px;
  line-height: 36px;
  color: ${({ theme }) => theme.textColorPrimary};
  appearance: none;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-color: #e3e3e5;
  }
  &::placeholder {
    color: #6c727c;
  }
`;

export interface ScSelectProps {
  disabled?: boolean;
  inline?: boolean;
  error?: boolean;
  simple?: boolean;
}

export const ScSelect = styled.div<ScSelectProps>`
  position: relative;
  color: ${({ theme }) => theme.textColorPrimary};
  font-size: 16px;
  box-sizing: border-box;
  width: 100%;

  ${({ inline }) => {
    if (inline) {
      return 'width: auto; padding: 0; border: 0;';
    }
    return undefined;
  }};

  .select-button {
    transition: background-color 0.3s ease;
    border: 1px solid
      ${({ theme, simple, error }) =>
        simple ? 'transparent' : error ? theme.error : theme.dividersColor};
    background-color: ${({ simple, theme, disabled }) =>
      simple ? 'transparent' : disabled ? theme.disabled : theme.inputBgColor};
    ${({ disabled }) =>
      disabled ? `border-color: transparent; cursor: not-allowed` : null};
    &:hover {
      background-color: ${({ theme, disabled }) =>
        disabled ? theme.disabled : theme.inputBgColorHover};
    }
    border-radius: 6px;
    padding: ${({ simple }) => (simple ? '0' : '16px')};
    color: ${({ theme }) => theme.textColorPrimary};
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    ${({ disabled, theme }) =>
      disabled
        ? `color: ${theme.textColorCaption}; cursor: not-allowed; `
        : null};
    & > span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 19px;
      ${({ inline }) => (inline ? ' margin-right: 5px;' : null)};
      &.default {
        color: ${({ theme }) => theme.textColorSecondary};
      }
      img {
        width: 16px;
        margin-right: 5px;
        vertical-align: middle;
      }
    }
    .select-dropdown-img {
      user-select: none;
      transition: all 0.3s ease;
      svg {
        path {
          fill: ${({ disabled }) => (disabled ? '#B8BEC6' : '#727B86')};
        }
      }
    }
    &.pressed {
      border-radius: 6px 6px 0 0;
      border-bottom: 1px solid
        ${({ theme, simple }) => (simple ? 'transparent' : theme.mainColor)};
      .select-dropdown-img {
        transform: rotateZ(180deg);
        svg path {
          fill: ${({ theme }) => theme.mainColor};
        }
      }
    }
  }
`;

export const ScDropDown = createStyledObject({
  Block: styled.div<{ medium?: boolean }>`
    z-index: 14;
    margin: 0;
    position: absolute;
    right: 0;
    left: 0;
    top: 100%;
    min-width: 80px;
    max-height: 240px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: ${({ theme }) => theme.dropdownBackground};
    box-shadow: ${({ theme }) => theme.dropdownShadow};
    border-radius: 0 0 6px 6px;
    border-right: 1px solid ${({ theme }) => theme.dividersColor};
    border-left: 1px solid ${({ theme }) => theme.dividersColor};
    border-bottom: 1px solid ${({ theme }) => theme.dividersColor};
    ${({ medium }) =>
      medium ? 'width: calc(100% + 200px); right: -100px;' : null};
    @media (${device.desktop}) {
      position: fixed;
      left: 0;
      right: 0;
      top: auto;
      width: auto;
      bottom: ${ScMobileMenuHeight};
      max-height: calc(100% - (${ScMobileMenuHeight}));
      padding-bottom: 0;
      border-radius: 4px 4px 0 0;
    }
  `,

  CloseButton: styled.div`
    display: none;
    z-index: 15;
    box-sizing: border-box;
    padding: 14px 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    order: 0;
    &:hover {
      cursor: pointer;
    }

    @media (${device.tablet}) {
      display: flex;
    }

    svg {
      fill: #caccd0;
      width: 21px;
      height: 9px;
    }
  `,

  SearchContainer: styled.div`
    z-index: 15;
    box-sizing: border-box;
    padding: 8px 14px;
    width: 100%;
    display: block;
    align-items: center;
    order: 1;
    @media (${device.tablet}) {
      padding: 14px;
      order: 3;
      box-shadow: ${({ theme }) => theme.triggerShadow};
    }
  `,

  ListContainer: styled.ul`
    box-sizing: border-box;
    padding: 4px 0;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    order: 2;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    border-radius: 6px;
  `,

  ListItem: styled.li`
    box-sizing: border-box;
    position: relative;
    padding: 14px;
    width: 100%;
    min-height: fit-content; // safari fix
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.textColorPrimary};
    font-size: 16px;
    line-height: 18px;
    user-select: none;
    &:hover {
      background: ${({ theme }) => theme.dropdownItemHover};
      cursor: pointer;
    }

    &.disabled {
      color: #c2c2c2;
      cursor: default;
      &:hover {
        background: none;
      }

      > div {
        padding-bottom: 20px;
      }

      img {
        opacity: 0.3;
      }
    }

    > a {
      width: 100%;
      padding: 14px;
      margin: -14px;
      color: inherit;
      font-size: inherit;
      font-weight: 400;
      position: relative;
      ${MTextOverflow};
      &:hover {
        text-decoration: none;
      }
      &.active {
        color: ${({ theme }) => theme.mainColor};
        background-color: ${({ theme }) => theme.dropdownItemActive};
      }
    }

    > img {
      display: flex;
      width: 25px;
      min-width: 25px;
      height: 25px;
      margin-right: 15px;
    }

    > span {
      ${MTextOverflow}
    }

    > .tooltip {
      position: absolute;
      bottom: 0;
      left: 15px;
      width: 100%;
      font-size: 12px;
      line-height: 12px;
      color: ${({ theme }) => theme.textColorCaption};
    }

    > .status {
      width: 8px;
      min-width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 12px;
      &--grey {
        background: ${({ theme }) => theme.textColorCaption};
      }
      &--red {
        background: ${({ theme }) => theme.error};
      }
      &--black {
        background: ${({ theme }) => theme.textColorPrimary};
      }
      &--pink {
        background: #de004c;
      }
      &--green {
        background: ${({ theme }) => theme.success};
      }
      &--blue {
        background: ${({ theme }) => theme.info};
      }
    }
  `,

  ListItemString: styled.span`
    width: 100%;
    ${MTextOverflow}
  `,
});

export const ScCheckboxError = styled.div`
  color: #be4040;
  margin-top: 5px;
  position: absolute;
  top: 100%;
  @media (${device.tablet}) {
    text-align: left !important;
    font-size: 11px !important;
  }
`;

export const ScAddButton = styled.div`
  background: ${({ theme }) => theme.mainColor};
  color: #fff;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 300;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ScCommentBtn = styled.button<{ small?: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.textColorPrimary};
  text-align: left;
  padding: 0;
  border-radius: 6px;
  letter-spacing: 0.012em;
  font-size: 16px;
  ${({ small }) => (small ? 'width: 293px;' : 'width: 100%;')};
  background: ${({ theme }) => theme.backgroundColorSecondary};
  border: 1px solid ${({ theme }) => theme.dividersColor};
  cursor: pointer;
  font-weight: 400;
  transition-duration: 0.3s;
  box-sizing: border-box;
  &:hover {
    background-color: ${({ theme }) => theme.inputBgColorHover};
  }
  @media (${device.tablet}) {
    width: 100%;
  }
  .icon {
    width: 24px;
    height: 24px;
    background: ${({ theme }) => theme.mainColor};
    border-radius: 5px;
    padding: 7px;
    margin-right: 13px;
    & > svg {
      width: 100%;
      height: 100%;
      path,
      circle {
        stroke: ${({ theme }) => theme.backgroundColorBlock};
        fill: ${({ theme }) => theme.backgroundColorBlock};
      }
    }
  }
`;

export const ScCheckbox = styled.div`
  min-width: 16px;
  width: 16px;
  height: 16px;
  .checkbox {
    display: inline-block;
    border-radius: 2px;
    background: #fff;
    width: 100%;
    height: 100%;
  }
  input {
    display: none !important;
  }
  span {
    border: 1px solid ${({ theme }) => theme.textColorCaption};
    box-sizing: border-box;
  }
  input:checked ~ span {
    border-color: ${({ theme }) => theme.mainColor};
    background: url(${checkbox}) no-repeat ${({ theme }) => theme.mainColor} 50%
      50%;
  }
`;

export const ScCheckboxLabel = styled.label`
  position: relative;
  color: ${({ theme }) => theme.textColorPrimary};
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (${device.tablet}) {
    align-items: flex-start;
  }
  ${ScCheckbox} {
    margin-right: 8px;
  }
  & > span {
    font-weight: 400;
    margin: 0 4px;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.textColorPrimary};
  }
`;

export const ScSelectedBox = styled.div`
  ul {
    font-weight: normal;
    font-size: 16px;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      color: #444;
      cursor: pointer;
      padding: 5px 10px;
      margin-right: 5px;
      border-radius: 4px;
      box-sizing: border-box;
      text-transform: uppercase;
      transition: all 0.3s ease;
      &:hover {
        color: #111c2b;
      }
      &:last-child {
        margin: 0;
      }
      &.selected {
        color: #fff;
        background: #2196f3;
        &:hover {
          color: #fff;
        }
      }
    }
  }
  p {
    color: #757575;
    font-size: 12px;
    line-height: 140%;
  }
`;

export const ScRadioInputGroup = styled.div`
  font-weight: normal;
  font-size: 16px;
  display: flex;
  margin-top: 13px;
  label {
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
  }
  input {
    display: none;
    &:checked + div {
      background-color: ${({ theme }) => theme.lgTagBgActive};
      color: ${({ theme }) => theme.lgTagTextColorActive};
      border-color: ${({ theme }) => theme.lgTagBorderActive};
      cursor: initial;
    }
  }
  div {
    font-size: 12px;
    line-height: 16px;
    background-color: ${({ theme }) => theme.lgTagBg};
    color: ${({ theme }) => theme.textColorPrimary};
    border: 1px solid ${({ theme }) => theme.dividersSecondaryColor};
    padding: 8px 12px;
    border-radius: 6px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background-color: ${({ theme }) => theme.lgTagBgHover};
      border-color: ${({ theme }) => theme.lgTagBorderHover};
    }
  }
`;

export const ScRelativeBlock = styled.div`
  position: relative;
  width: 100%;
`;
