import React from 'react';
import { useTranslation } from 'react-i18next';

import { capitalize } from 'helpers/common';

import {
  ScAuthenticatedFooter,
  ScAnonymousFooter,
} from 'components/footer/styled';

import { LanguageDropdown } from 'components/languageDropdown';

import { ReactComponent as EmailIcon } from 'imgs/email-icon.svg';

export const AnonymousFooter = () => {
  const { t } = useTranslation();

  return (
    <ScAnonymousFooter>
      <div>
        <p className="copy">
          Copyright © {new Date().getFullYear()} Almapays.com{' '}
          {t('common:footer.copyright')}
        </p>
        {process.env.REACT_APP_SUPPORT_MAIL && (
          <a
            href={`mailto:${process.env.REACT_APP_SUPPORT_MAIL}`}
            className="email"
          >
            <EmailIcon />
            {process.env.REACT_APP_SUPPORT_MAIL}
          </a>
        )}
      </div>
      <LanguageDropdown />
    </ScAnonymousFooter>
  );
};

export const AuthenticatedFooter = () => {
  const { t } = useTranslation();

  return (
    <ScAuthenticatedFooter>
      Copyright © {new Date().getFullYear()}{' '}
      {process.env.REACT_APP_THEME
        ? capitalize(process.env.REACT_APP_THEME)
        : ''}{' '}
      {t('common:footer.copyright')}
    </ScAuthenticatedFooter>
  );
};
