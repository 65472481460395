import styled from 'styled-components/macro';
import { device } from 'components/global/MediaQuery';

export const ScAnonymousFooter = styled.footer`
   {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    max-width: 1052px;
    font: 400 14px/24px ${({ theme }) => theme.mainFontFamily};
    padding: 27px 10px;
    margin: 20px auto 0 auto;
    box-sizing: border-box;
    @media (${device.tablet}) {
      flex-direction: column;
      align-items: center;
      text-align: center;
      max-width: 100%;
    }
    .copy {
      color: ${({ theme }) => theme.textColorSecondary};
      margin: 0 0 7px 0;
    }
    .email {
      color: ${({ theme }) => theme.linkColor};
      @media (${device.tablet}) {
        margin-bottom: 15px;
        display: inline-block;
      }
      & > svg {
        vertical-align: middle;
        margin-right: 5px;
        path {
          fill: ${({ theme }) => theme.linkColor};
        }
      }
    }
  }
`;

export const ScAuthenticatedFooter = styled.footer`
  padding: 27px 0;
  text-align: center;
  margin-top: 20px;
  width: calc(100% - 160px);
  margin-left: auto;
  @media (${device.desktop}) {
    width: 100%;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @media (${device.mobileM}) {
    border: 0;
  }
  a {
    color: ${({ theme }) => theme.textColorSecondary};
    font-size: 13px;
    margin-right: 45px;
    &:last-child {
      margin: 0;
    }
    &:hover {
      text-decoration: none;
      color: ${({ theme }) => theme.textColorPrimary};
    }
    @media (${device.desktop}) {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
`;
