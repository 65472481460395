import styled from 'styled-components/macro';
import { device } from 'components/global/MediaQuery';
import { ScCommentBtn, ScInputBlock } from 'components/input/styled';
import { ScBlockShowAnimation } from 'components/global/Animations';

import lockIcon from 'imgs/lock-icon.svg';
import сloseIcon from 'imgs/close-gray-icon.svg';
import warningRedIcon from 'imgs/warning-red-icon.svg';
import infoIcon from 'imgs/warning-icon.svg';

interface ScBlockProps {
  marginTop?: boolean;
  small?: boolean;
  center?: boolean;
  locked?: boolean;
  verticalAlign?: boolean;
}
export const ScBlock = styled.div<ScBlockProps>`
  position: relative;
  ${({ verticalAlign }) =>
    verticalAlign ? `top: 50%; transform: translateY(-50%);` : null};
  ${({ small }) => (small ? `width: 460px;` : null)};
  ${({ center }) => (center ? `margin: 0 auto;` : null)};
  background: ${({ theme }) => theme.backgroundColorBlock};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.dividersColor};
  box-sizing: border-box;
  ${({ marginTop }) => (marginTop ? `margin-top:25px` : null)};
  @media (max-width: 768px) {
    width: 100%;
  }
  &:nth-child(1n + 2) {
    margin-top: 20px;
    @media (${device.tablet}) {
      margin-top: 15px;
    }
  }
  &:before {
    content: ${({ locked }) => (locked ? "''" : 'none')};
    background: rgba(251, 252, 253, 0.8) url(${lockIcon}) no-repeat 50% 50%;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    border-radius: 12px;
  }
  h6 {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: ${({ theme }) => theme.textColorPrimary};
    margin: 0 0 16px 0;
  }
`;

export const ScBlockWithPaddings = styled.div`
  padding: 0 28px;
`;

export const ScBlockHeader = styled.div<{ withButtonClose?: boolean }>`
  border-radius: 12px 12px 0 0;
  color: ${({ theme }) => theme.textColorPrimary};
  margin: 0 0 24px 0;
  padding: 25px 28px 13px 28px;
  border-bottom: 1px solid ${({ theme }) => theme.dividersColor};
  position: relative;
  background-color: ${({ theme }) => theme.headerBgColor};
  @media (${device.mobileL}) {
    margin: 0 0 13px 0;
  }

  h2 {
    margin: 0;
    font: ${({ theme }) => theme.blockHeaderFont}
      ${({ theme }) => theme.mainFontFamily};
    color: ${({ theme }) => theme.textColorPrimary};
    @media (${device.mobileL}) {
      font-size: 14px;
      line-height: 20px;
    }
    & > div {
      display: inline-block;
    }
    span {
      color: ${({ theme }) => theme.textColorPrimary};
      font-size: 12px;
      font-weight: 400;
      cursor: pointer;
    }
    b {
      font-weight: 500;
      i {
        font-style: normal;
        @media (${device.mobileL}) {
          display: none;
        }
      }
    }
  }
`;

export const ScBlockWithCaptcha = styled.div`
  margin: 0 auto;
`;

export const ScButtonClose = styled.button`
  display: block;
  background-color: transparent;
  background-image: url(${сloseIcon});
  background-position: center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  border: none;
  position: absolute;
  right: 28px;
  top: 25px;
  cursor: pointer;
`;

export const ScBlockBody = styled.div`
  padding: 0 28px 28px 28px;
  min-height: 60px;
  position: relative;
  .icon {
    text-align: center;
    margin-bottom: 16px;
  }
`;
export const ScBlockBodyNoHeader = styled.div`
  padding: 20px 30px;
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
  }
  p {
    font-size: 14px;
    line-height: 24px;
  }

  .code {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .important-info {
    font-weight: bold;
  }
`;
export const ScBlockBodyTitle = styled.h2`
  color: ${({ theme }) => theme.textColorSecondary};
  font-size: 16px;
  padding: 20px 0;
  margin: 0;
  font-weight: 400;
  text-align: center;
`;
export const ScBlockBodyHeader = styled.div<{ noPaddingTop?: boolean }>`
  padding: ${({ noPaddingTop }) => (noPaddingTop ? '0' : '20px')} 30px 20px 30px;
  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 20px 0;
  }
  p {
    color: ${({ theme }) => theme.textColorSecondary};
    font-size: 16px;
    line-height: 24px;
  }
`;
// prettier-ignore
export const ScBlockBodyTitleExpanded = styled(ScBlockBodyTitle)<{bgBlue?: true;}>`
  padding: 10px 20px 20px 20px;
  ${({ bgBlue }) => bgBlue && 'background: #E9F5FE;'}

  @media (${device.mobileL}) {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const ScBlockBodyExpanded = styled.div`
  padding: 20px 0;
`;
export const ScBlockSection = styled.div`
  padding: 0;
`;
export const ScBlockSectionHeader = styled.div`
  background: ${({ theme }) => theme.backgroundColorSecondary};
  border-top: 1px solid ${({ theme }) => theme.dividersColor};
  border-bottom: 1px solid ${({ theme }) => theme.dividersColor};
  padding: 12px 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.textColorPrimary};
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
`;
export const ScBlockSectionBody = styled.div`
  padding: 0;
`;
interface ScWrapProps {
  noPadding?: boolean;
  bordered?: boolean;
  spaceBetween?: boolean;
  topPadding?: string;
  bottomPadding?: string;
  flying?: boolean;
  vertical?: boolean;
  customVersion?: boolean;
  customButton?: boolean;
  fullWidthButton?: boolean;
}
export const ScWrap = styled.div<ScWrapProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ spaceBetween }) =>
    spaceBetween ? 'space-between' : 'center'};
  ${({ bordered, theme }) =>
    bordered && `border-top: 1px solid ${theme.dividersColor};`};
  position: relative;
  ${({ noPadding }) => (noPadding ? null : 'padding: 28px 0; margin: 0 28px;')};
  ${({ bottomPadding }) =>
    bottomPadding && `padding-bottom: ${bottomPadding}px;`};
  ${({ topPadding }) => topPadding && `padding-top: ${topPadding}px;`};
  button {
    ${({ customButton }) => (customButton ? 'width: 184px' : null)};
    flex: ${({ fullWidthButton }) => (fullWidthButton ? 1 : 'initial')};
  }
  @media (${device.tablet}) {
    flex-direction: column;
    ${({ customVersion }) =>
      customVersion
        ? 'padding: 24px 30px; margin: 0; flex-direction: column-reverse;'
        : null};
  }
  button,
  a {
    margin-right: 20px;
    @media (${device.tablet}) {
      margin: ${({ customVersion }) => (customVersion ? `16px 0 0` : `12px 0`)};
      width: 100%;
    }
    button {
      @media (${device.tablet}) {
        width: 100%;
      }
    }
    &:last-child {
      margin: 0;
    }
  }

  ${({ flying, theme }) =>
    flying
      ? 'position: fixed;' +
        'z-index: 10; ' +
        'bottom: 0;' +
        'margin: 0;' +
        `background:${theme.backgroundColorBlock}E6;` +
        'width: 775px;' +
        'padding-bottom: 30px;' +
        'border: 0;' +
        'border-top: 1px solid rgba(166, 171, 178, .3);' +
        `@media (${device.desktop}) {padding-bottom: 70px; width: calc(100% - 35px);};` +
        `@media (${device.tablet}) {width: 100%;};` +
        '& > div{position: absolute; background: rgba(255, 255, 255, .9); top: -60px; border-top: 1px solid rgba(166, 171, 178, .3)}'
      : null};
  ${({ vertical }) =>
    vertical
      ? `@media (${device.mobileL}) {button{width: 100%; margin: 0 0 5px 0;}}`
      : null};

  flex-flow: row wrap;
  box-sizing: border-box;

  form {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
`;

export const ScWrapBordered = styled(ScWrap)<{ bottomPadding?: string }>`
  border-top: 1px solid ${({ theme }) => theme.dividersColor};
  padding: 30px 0;
  margin: 0 30px;
  ${({ bottomPadding }) =>
    bottomPadding ? `padding-bottom: ${bottomPadding}px;` : null}
`;
interface ScLabeledBlockProps {
  noBorder?: boolean;
  small?: boolean;
  paddingBottom?: string;
  borderTop?: true;
  mobMarginTop?: boolean;
}

export const ScLabeledBlock = styled.div<ScLabeledBlockProps>`
  ${({ small }) => (small ? null : 'padding: 30px 0;')}
  ${({ noBorder, theme }) =>
    noBorder ? null : `border-bottom: 1px solid ${theme.dividersColor};`}
  display: flex;
  margin: 0 30px;
  ${({ paddingBottom }) =>
    paddingBottom ? `padding-bottom: ${paddingBottom}` : null}
  ${({ borderTop, theme }) =>
    borderTop ? `border-top: 1px solid ${theme.dividersColor};` : null};

  @media (${device.tablet}) {
    flex-direction: column;
    padding: 15px 0;
    border: 0;
    ${({ mobMarginTop }) => mobMarginTop && `margin-top: 24px;`}
  }
`;

interface ScLabeledBlockTitleProps {
  center?: boolean;
}

export const ScLabeledBlockTitle = styled.div<ScLabeledBlockTitleProps>`
  width: 180px;
  margin-right: 24px;
  ${({ center }) =>
    center
      ? `display: flex;
  flex-direction: column;
  justify-content: center;`
      : null}

  @media (${device.tablet}) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  h2 {
    color: ${({ theme }) => theme.textColorPrimary};
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
    margin: 0;
  }
  p {
    color: ${({ theme }) => theme.textColorCaption};
    font-size: 12px;
    line-height: 16px;
    &.mobMarginTop {
      margin-top: 4px;
    }
    @media (${device.tablet}) {
      font-size: 14px;
    }
  }
`;
export const ScLabeledBlockBody = styled.div`
  position: relative;
  width: 60%;
  & > div {
    width: 425px;
  }
  @media (${device.tablet}) {
    width: 100%;
    & > div {
      width: 100%;
    }
  }
  //p {
  //  max-width: 294px;
  //}
  .text-block {
    position: relative;
    margin: 20px 0 15px 0;
    font-size: 18px;
    @media (${device.tablet}) {
      margin: 10px 0 0 0;
    }
  }
`;

export const ScCommentBlock = styled.div`
  & > div {
    margin: 0;
  }
  @media (${device.tablet}) {
    max-width: 100%;
  }
  .input__label {
    color: ${({ theme }) => theme.mainColor};
  }
  ${ScInputBlock} + ${ScCommentBtn} {
    background-color: ${({ theme }) => theme.dropdownItemActive};
    margin-top: 24px;
  }
`;

export const ScEndingPageBody = styled.div`
  text-align: center;
  width: 300px;
  margin: 27px auto 10px auto;
  @media (${device.mobileXL}) {
    width: 100%;
  }
  h3 {
    color: ${({ theme }) => theme.textColorPrimary};
    margin: 20px 0;
    font-size: 18px;
    font-weight: 400;
  }
  p {
    color: ${({ theme }) => theme.textColorSecondary};
    line-height: 21px;
    font-size: 14px;
    margin-bottom: 0;
  }
  svg path {
    fill: ${({ theme }) => theme.success};
  }
`;

export const ScTabMenu = styled.div`
  margin: 12px 30px 0 30px;
  padding: 0 20px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.backgroundColorSecondary};
  a,
  button {
    position: relative;
    padding: 12px 4px 14px 4px;
    margin: 0 64px 0 0;
    display: inline-block;
    border: none;
    color: ${({ theme }) => theme.textColorSecondary};
    transition: color 0.3s ease;
    font-size: 12px;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    @media (${device.tablet}) {
      margin-right: 40px;
    }
    @media (${device.mobileL}) {
      margin-right: 24px;
    }
    &:last-child {
      margin: 0;
    }
    &.active {
      color: ${({ theme }) => theme.mainColor};
    }
    &:hover {
      background-color: ${({ theme }) => theme.dropdownItemHover};
      text-decoration: none;
    }
  }
  @media (${device.tablet}) {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }
`;

export const ScTabMenuPersonal = styled(ScTabMenu)`
  display: flex;
  justify-content: space-between;
  a,
  button {
    margin: 0;
    @media (${device.mobileXXL}) {
      margin-right: 15px;
    }
    &:last-child {
      margin: 0;
    }
  }
`;
export const ScGaBlock = styled.div`
  width: 100%;
  flex: 1 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    width: 290px;
    margin: 0 0 20px 0;
    box-sizing: border-box;
    @media (${device.mobileL}) {
      padding-left: 30px;
      padding-right: 30px;
      width: 100%;
    }
  }
`;
interface ScListBlockProps {
  borderBottom?: boolean;
  small?: boolean;
  summary?: boolean;
}
export const ScListBlock = styled.ul<ScListBlockProps>`
  display: flex;
  align-self: flex-end;
  flex: 1 100%;
  list-style: none;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 30px;
  padding: ${({ small }) => (small ? '0' : ' 30px 0')};
  border: 0;
  transition: 0.3s ease;
  ${({ borderBottom, theme }) =>
    borderBottom ? `border-bottom:1px solid ${theme.dividersColor};` : null};
  @media (${device.tablet}) {
    padding: ${({ small }) => (small ? '0' : ' 15px 0')};
  }
  li {
    margin-bottom: 13px;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.textColorCaption};
    text-align: left;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    &:last-child {
      margin: 0;
      border: 0;
    }
    &.total {
      color: ${({ theme }) => theme.textColorCaption};
      border-top: 1px solid ${({ theme }) => theme.dividersColor};
      padding-top: 12px;
      font-size: 16px;
      line-height: 24px;
      margin-top: 20px;
      & > span:last-child {
        font-weight: 500;
      }
    }
    .word-break {
      word-break: break-all;
    }
    span {
      display: inline-block;
      ${({ summary, theme }) =>
        summary ? `color: ${theme.textColorPrimary}; font-size: 18px` : null};
      &:first-child {
        min-width: 176px;
        width: 176px;
        margin-right: 24px;
        @media (${device.tablet}) {
          margin-right: 10px;
        }
      }
      &:last-child {
        color: ${({ theme }) => theme.textColorPrimary};
        display: flex;
        align-items: center;
        font-weight: ${({ summary }) => (summary ? '700' : '400')};
        @media (${device.mobileXXL}) {
          margin-top: 5px;
        }
        img {
          width: 25px;
          margin-right: 5px;
        }
      }
    }

    @media (${device.mobileXXL}) {
      flex-direction: column;
    }
  }
  .buttonBlock {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid ${({ theme }) => theme.backgroundColorMain};
  }
`;

export const ScErrorBlock = styled.div<{ marginTop?: boolean }>`
  position: relative;
  background: ${({ theme }) => theme.backgroundColorBlock};
  color: ${({ theme }) => theme.textColorPrimary};
  font-size: 16px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.dividersColor}
  box-sizing: border-box;
  -webkit-animation: slowShow 1s;
  animation: slowShow 1s;
  padding: 24px 15px;
  display: flex;
  align-items: center;
  ${({ marginTop }) => (marginTop ? 'margin-top: 30px' : null)};
  svg {
    width: 24px;
    height: 24px;
    margin-right: 15px;
    path {
      fill: ${({ theme }) => theme.error};
    }
  }
  &:before {
    content: '';
    position: absolute;
    border-radius: 12px 0 0 12px;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background: ${({ theme }) => theme.error};
  }
  button {
    font-size: 16px;
  }
  .error-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    span {
      margin-bottom: 6px;
    }
  }
`;

export const ScNotFoundBlock = styled.div`
  color: ${({ theme }) => theme.textColorPrimary};
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  .text-grey {
    color: ${({ theme }) => theme.textColorSecondary};
  }
  h2 {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 15px;
    margin-top: 0;
    b {
      font-weight: 500;
    }
  }
  p {
    margin: 8px 0;
  }
  svg {
    margin-top: 60px;
    width: 100%;
    @media (${device.mobileL}) {
      margin-top: 0;
    }
  }
`;

export const ScDelShopBlock = styled.div`
  margin: 13px 30px;
  @media (${device.mobileL}) {
    margin: 0 30px;
  }
`;

export const ScInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 28px;
  }
  & .addPhone-btn {
    margin-bottom: 30px;
    margin-top: 20px;
  }
`;

export const ScFormMessage = styled.div`
  color: ${({ theme }) => theme.textColorSecondary};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
`;

export const ScSendEmailGroup = styled.div`
  //width: 214px;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.014em;
  color: #525252;
  flex: 1;
  span,
  button {
    line-height: 39px;
  }
`;

export const ScModalRecaptchaBlock = styled.div`
  padding: 30px 0 20px;
`;

export const ScTextAlignRight = styled.div`
  text-align: right;
`;

export const ScCollapseBlock = styled.div`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
    margin: 0 30px;
    border-top: 1px solid ${({ theme }) => theme.dividersColor};

    h3 {
      color: ${({ theme }) => theme.textColorPrimary};
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      padding: 12px 0 24px 0;
      margin: 0;
      cursor: pointer;
    }
    p {
      color: ${({ theme }) => theme.textColorSecondary};
      font-size: 16px;
      margin: 16px 0;
      padding: 0;
    }
    img {
      transition: all 0.3s ease-in;
    }
    .open {
      transform: rotateZ(180deg);
    }
    &--disabled {
      cursor: default;
      flex-direction: column;
      align-items: flex-start;
      padding: 25px 0;
      h3 {
        cursor: default;
        margin: 0;
        padding: 0;
      }
      button {
        align-self: center;
      }
    }
  }
`;

export const StepsBody = styled.div`
  margin: 0 30px;
  .body {
    display: flex;
    flex-direction: column;
    &__text {
      color: ${({ theme }) => theme.textColorSecondary};
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 30px;
    }

    .step {
      display: flex;
      margin-bottom: 20px;
      @media (${device.tablet}) {
        flex-direction: column;
      }
      &__number {
        color: ${({ theme }) => theme.mainColorHigh};
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        margin-right: 8px;
        @media (${device.tablet}) {
          margin: 0;
          text-align: center;
        }
      }
      &__content {
        min-width: 460px;
        flex-basis: 460px;
        @media (${device.tablet}) {
          min-width: 100%;
          flex-basis: 100%;
          button {
            margin: 0 auto;
          }
        }
      }
      p {
        color: #727B86;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin: 0;
      }
      h3 {
        margin: 0 0 4px 0;
        padding: 0;
        color: ${({ theme }) => theme.textColorPrimary};
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .qr {
    position: relative;
    border: 1px solid ${({ theme }) => theme.dividersColor};
    border-radius: 6px;
    min-width: 183px;
    width: 183px;
    height: 183px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 24px;
    box-sizing: border-box;
    @media (${device.tablet}) {
      margin: 10px auto;
    }
    span {
      color: #727B86;
      font-size: 11px;
      line-height: 15px;
      text-align: center;
    }
  }
  .download-links {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media (${device.tablet}) {
      justify-content: space-between;
    }
    a {
      font-weight: 500;
      font-size: 12px;
      margin-right: 20px;
      @media (${device.tablet}) {
        margin: 0;
        &:last-child {
          margin: 0;
        }
      }
    }
`;

export const ScErrorText = styled.p`
  color: ${({ theme }) => theme.error};
  text-align: center;
  padding: 0;
  margin: 0 0 8px 0;
  height: 13px;
`;

export const BlockMargin = styled.div`
  height: 30px;

  @media (${device.tablet}) {
    height: 15px;
  }
`;

export const ScBlockResult = styled.div`
  padding: 24px 0;
  margin: 0 30px;
  border-top: 1px solid ${({ theme }) => theme.dividersColor};
  @media (${device.tablet}) {
    margin-top: 12px;
  }
`;

export const ScBlockResultItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 18px;
  @media (${device.tablet}) {
    justify-content: space-between;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const ScBlockResultItemTitle = styled.div`
  width: 180px;
  margin-right: 24px;
  @media (${device.tablet}) {
    width: auto;
  }
  h2 {
    color: ${({ theme }) => theme.textColorPrimary};
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
    margin: 0;
    &.small {
      color: #6c727c;
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

export const ScBlockResultItemBody = styled.div`
  position: relative;
  width: 60%;
  @media (${device.tablet}) {
    width: auto;
  }
  .text-block {
    color: ${({ theme }) => theme.textColorPrimary};
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
    margin: 0;
    &.bold {
      font-weight: bold;
    }
    &.small {
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

export const ScBlockInfo = styled.div`
  position: relative;
  background: #fef9e9;
  border-radius: 6px;
  padding: 12px 12px 12px 40px;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 30px;
  animation: ${ScBlockShowAnimation} 0.3s ease-in;
  &:before {
    content: '';
    display: block;
    background: url(${infoIcon}) center no-repeat;
    width: 19px;
    height: 17px;
    position: absolute;
    left: 12px;
    top: 12px;
  }
  & > *:first-child {
    margin-top: 0;
  }
`;

export const ScBlockWarning = styled.div`
  position: relative;
  background: ${({ theme }) => theme.warningTooltipBg};
  border-radius: 6px;
  padding: 12px 12px 12px 40px;
  font-size: 12px;
  line-height: 14px;
  animation: ${ScBlockShowAnimation} 0.3s ease-in;
  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: ${({ theme }) => theme.textColorPrimary};
    margin: 0 0 4px 0;
  }
  &:before {
    content: '';
    display: block;
    background: url(${infoIcon}) center no-repeat;
    width: 19px;
    height: 17px;
    position: absolute;
    left: 12px;
    top: 12px;
  }
  & > *:first-child {
    margin-top: 0;
  }
`;

export const ScInputAddon = styled.div`
  font-size: 16px;
`;

export const ScFormVerRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin: 0 -14px;
  @media (${device.tablet}) {
    flex-wrap: wrap;
  }
`;

export const ScFormVerCol1 = styled.div`
  flex: 0 0 322px;
  padding: 0 14px;
  @media (${device.tablet}) {
    flex: auto;
  }
`;

export const ScFormVerCol2 = styled.div`
  flex: 1 1 270px;
  padding: 0 14px;
  @media (${device.tablet}) {
    flex: auto;
    margin-top: 30px;
  }
`;

export const ScRules = styled.div`
  h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-top: 0;
  }

  .image {
    margin-bottom: 24px;
    img {
      width: 100%;
      vertical-align: top;
    }
  }

  .selfie-warning {
    margin-bottom: 16px;
    &__img {
      margin-bottom: 16px;
      img {
        width: 100%;
        vertical-align: top;
      }
    }
    &__content {
      background: #fef9e9;
      color: #111c2b;
      border-radius: 0 0 4px 4px;
      padding: 20px;
      .title {
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        margin-bottom: 10px;
        img {
          vertical-align: middle;
          margin-top: -2px;
          margin-right: 3px;
        }
      }
      .message {
        font-size: 13px;
        line-height: 18px;
        strong {
          font-weight: 700;
        }
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    & > li {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      font-size: 14px;
      line-height: 20px;
      color: ${({ theme }) => theme.textColorCaption};
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      .ico {
        padding-right: 8px;
      }
      .text {
        flex: auto;
        &__item {
          margin-bottom: 16px;
          & > span {
            color: ${({ theme }) => theme.textColorSecondary};
            font-weight: 500;
            margin-right: 4px;
          }
        }
        &__row {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          margin: 0 -5px;
          box-sizing: border-box;
        }
        &__col {
          width: 50%;
          padding: 0 5px;
          box-sizing: border-box;
        }
        &__t-min {
          color: #111c2b;
          margin-bottom: 8px;
        }
      }
    }
  }
`;

interface ScWarningProps {
  withoutIcon?: boolean;
  marginBottom?: string;
  width?: string;
}

export const ScWarning = styled.div<ScWarningProps>`
  margin: 24px 30px
    ${({ marginBottom }) => (marginBottom ? marginBottom : '0px')} 30px;
  background: rgba(246, 101, 111, 0.1);
  border-radius: 4px;
  padding: 12px 36px;
  position: relative;
  width: ${({ width }) => (width ? width : 'auto')};
  &:before {
    content: '';
    display: ${({ withoutIcon }) => (withoutIcon ? 'none' : 'block')};
    position: absolute;
    background: url(${warningRedIcon}) center no-repeat;
    width: 16px;
    height: 16px;
    left: 12px;
    top: 12px;
  }
`;

export const ScOnReviewBlock = styled.div`
  width: 100%;
  text-align: center;
  padding: 0;
  @media (${device.tablet}) {
    padding: 30px 0;
  }
  .ico {
    margin-bottom: 24px;
    img {
      vertical-align: middle;
    }
  }
  .message {
    max-width: 360px;
    margin: 0 auto;
    h3 {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 152.34%;
      margin: 0;
      color: ${({ theme }) => theme.textColorPrimary};
    }
    p {
      font-size: 14px;
      line-height: 21px;
      color: ${({ theme }) => theme.textColorCaption};
      margin: 8px 0 0;
    }
  }
`;

export const ScRelativeBlock = styled.div`
  position: relative;
`;

export const ScBreakWord = styled.div`
  word-break: break-word;
`;
