import React, { FC } from 'react';
import { ErrorBlock } from 'components/block';
import { useTranslation } from 'react-i18next';

export const InternalErrorBlock: FC = () => {
  const { t } = useTranslation('common');
  return (
    <ErrorBlock withReload>
      {t(['common:internalError', 'Something went wrong'])}
    </ErrorBlock>
  );
};
