import styled from 'styled-components/macro';
import { device } from 'components/global/MediaQuery';

export const ScAccountDropDown = styled.div`
  z-index: 10;
  position: absolute;
  max-width: 280px;
  min-width: 260px;
  top: 100%;
  padding: 0;
  margin: 0;
  background: ${({ theme }) => theme.dropdownBackground};
  border: ${({ theme }) => `1px solid ${theme.dividersColor}`};
  box-shadow: ${({ theme }) => theme.dropdownShadow};
  border-radius: 4px;
  right: -10px;
  overflow: hidden;
  @media (${device.desktop}) {
    right: 0;
  }
`;

export const ScAccountInfoSection = styled.div`
  margin: 0 16px;
  box-sizing: border-box;
  @media (${device.mobileL}) {
    margin: 15px 12px;
  }
  .email {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: ${({ theme }) => theme.textColorPrimary};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 250px;
  }
  .account-number {
    position: relative;
    display: inline-block;
    color: #727b86;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 4px 24px 4px 4px;
    border-radius: 8px;
    background-color: transparent;
    transition: backgroundColor 0.2s;
    cursor: pointer;

    &:hover {
      background-color: #e9effa;
    }

    svg {
      vertical-align: middle;
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .status-verified {
    margin-top: 2px;
  }

  .status {
    display: inline-block;
    color: ${({ theme }) => theme.error};
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
    padding: 4px 12px;
    background: rgba(215, 90, 74, 0.1);
    border-radius: 8px;
    white-space: nowrap;
    &.verified {
      color: ${({ theme }) => theme.success};
      background: rgba(156, 215, 74, 0.1);
    }
  }
`;

export const ScAccountNav = styled.div`
  & > ul {
    list-style: none;
    padding: 0;
    margin: 0;
    &:not(:first-child) {
      border-top: 1px solid ${({ theme }) => theme.dividersColor};
    }
    & > li {
      font-size: 16px;
      color: ${({ theme }) => theme.textColorPrimary};
      white-space: nowrap;
      button,
      a {
        box-sizing: border-box;
        position: relative;
        display: block;
        width: 100%;
        text-align: left;
        font-size: 14px;
        color: ${({ theme }) => theme.textColorPrimary};
        padding: 14px 15px 13px 15px;
        background-color: transparent;
        transition: background-color 0.2s;
        &.with-icon {
          padding: 14px 15px 13px 48px;
        }
        svg {
          display: block;
          position: absolute;
          left: 15px;
          top: 50%;
          transform: translateY(-50%);
        }
        &:hover {
          text-decoration: none;
          background: ${({ theme }) => theme.dropdownItemHover};
        }
        .status-verified {
          position: absolute;
          right: 17px;
          top: 25%;
        }
        .status {
          display: inline-block;
          color: ${({ theme }) => theme.error};
          font-size: 10px;
          line-height: 12px;
          font-weight: 400;
          padding: 4px 12px;
          background: rgba(215, 90, 74, 0.1);
          border-radius: 8px;
          white-space: nowrap;
          &.verified {
            color: ${({ theme }) => theme.success};
            background: rgba(156, 215, 74, 0.1);
          }
        }
      }
    }
  }
`;

interface ScAvatarIconProps {
  isActive: boolean;
}

export const ScAvatarIcon = styled.div<ScAvatarIconProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  svg {
    margin-left: 11px;
    transition: transform 0.2s;
    transform: ${({ isActive }) =>
      isActive ? 'rotate(180deg)' : 'rotate(0deg)'};
    path {
      fill: ${({ theme }) => theme.textColorPrimary};
    }
  }
`;

interface ScAvatarProps {
  gradient: string;
  firstTwoLetters: string;
}

export const ScAvatar = styled.div<ScAvatarProps>`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: ${({ gradient }) => `linear-gradient(${gradient})`};
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.textColorPrimary};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  & > div {
    position: relative;
    &::before {
      content: ${({ firstTwoLetters }) => `"${firstTwoLetters}"`};
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      font-weight: 900;
      font-size: 70px;
      line-height: 82px;
      color: rgba(255, 255, 255, 0.1);
    }
  }
`;
