import React, { FC, PropsWithChildren, useEffect } from 'react';
import * as Sentry from '@sentry/browser';

import { useApi } from 'hooks/api';
import { isAuthenticated as isAuthenticatedRequest } from 'api/auth';
import { setAuthService } from 'services/authService';
import { Loader } from 'components/loader';
import { InternalErrorBlock } from 'components/internalError';

import { AuthService } from './service';
import { AuthServiceContext } from './context';

export const AuthServiceProvider: FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const [
    checkAuthentication,
    {
      data: isAuthenticated,
      pending: authenticationCheckPending,
      error: authenticationCheckError,
      errorMessage: authenticationCheckErrorMessage,
    },
  ] = useApi(
    isAuthenticatedRequest,
    { pending: true },
    { dispatchOnCancel: true }
  );

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  useEffect(() => {
    if (authenticationCheckError != null) {
      Sentry.captureMessage(
        `AUTH REQUEST FAILED\nCode: ${authenticationCheckError}\nMessage: ${authenticationCheckErrorMessage}`,
        'fatal'
      );
    }
  }, [authenticationCheckError, authenticationCheckErrorMessage]);

  if (authenticationCheckPending) {
    return <Loader />;
  }

  if (authenticationCheckError != null || isAuthenticated == null) {
    return (
      <section>
        <InternalErrorBlock />
      </section>
    );
  }

  const authService = new AuthService(isAuthenticated);

  // makes auth service accessible for non react tree code
  setAuthService(authService);

  return (
    <AuthServiceContext.Provider value={authService}>
      {children}
    </AuthServiceContext.Provider>
  );
};
